import {
	ReadQuestionnaires,
	ReadQuestionnairesFulfilled,
	READ_QUESTIONNAIRES,
	READ_QUESTIONNAIRES_FULFILLED,
	InternalDashboardQuestionnaire,
	CANCEL_QUESTIONNAIRE,
	CancelQuestionnaire,
	CANCEL_QUESTIONNAIRE_FULFILLED,
	CancelQuestionnaireFulfilled,
	ScheduleQuestionnaire,
	SCHEDULE_QUESTIONNAIRE,
	ACTIVATE_QUESTIONNAIRE,
	ActivateQuestionnaire,
	SCHEDULE_QUESTIONNAIRE_FULFILLED,
	ScheduleQuestionnaireFulfilled,
	ResetQuestionnaire,
	RESET_QUESTIONNAIRE,
	PatchQuestionnaires,
	PatchQuestionnairesFulfilled,
	PATCH_QUESTIONNAIRES,
	PATCH_QUESTIONNAIRES_FULFILLED,
	UpdatePixelId,
	UPDATE_PIXELID,
} from './type';

export const readQuestionnaires: (brandId: string) => ReadQuestionnaires = (payload) => ({
	type: READ_QUESTIONNAIRES,
	payload,
});

export const readQuestionnairesFulfilledAction: (
	payload: InternalDashboardQuestionnaire
) => ReadQuestionnairesFulfilled = (payload) => ({
	type: READ_QUESTIONNAIRES_FULFILLED,
	payload,
});
export const patchQuestionnaires: ({questionnaireId,allowMultipleResponse}: {questionnaireId:string,allowMultipleResponse:boolean}) => PatchQuestionnaires = (payload) => ({
	type: PATCH_QUESTIONNAIRES,
	payload,
});

export const patchQuestionnairesFulfilledAction: (
	payload: {questionnaireId:string,allowMultipleResponse:boolean},
) => PatchQuestionnairesFulfilled = (payload) => ({
	type: PATCH_QUESTIONNAIRES_FULFILLED,
	payload,
});

export const cancelQuestionnaireAction: (questionnaireId: string) => CancelQuestionnaire = (payload) => ({
	type: CANCEL_QUESTIONNAIRE,
	payload,
});

export const cancelQuestionnaireFulfilledAction: (questionnaireId: string) => CancelQuestionnaireFulfilled = (
	payload
) => ({
	type: CANCEL_QUESTIONNAIRE_FULFILLED,
	payload,
});

export const scheduleQuestionnaireAction: (questionnaireId: string) => ScheduleQuestionnaire = (payload) => ({
	type: SCHEDULE_QUESTIONNAIRE,
	payload,
});

export const scheduleQuestionnaireFulfilledAction: (questionnaireId: string) => ScheduleQuestionnaireFulfilled = (
	payload
) => ({
	type: SCHEDULE_QUESTIONNAIRE_FULFILLED,
	payload,
});

export const activateQuestionnaireAction: (questionnaireId: string) => ActivateQuestionnaire = (payload) => ({
	type: ACTIVATE_QUESTIONNAIRE,
	payload,
});

export const resetQuestionnaireAction: () => ResetQuestionnaire = () => ({
	type: RESET_QUESTIONNAIRE,
	payload: null,
});

export const updatePixelId: ({
	questionnaireId,
	pixelId,
}: {
	questionnaireId: string;
	pixelId: string | null;
}) => UpdatePixelId = (payload) => ({
	type: UPDATE_PIXELID,
	payload,
});
