import { omit } from 'ramda';
import { Niche } from '../../../shared/types/Niche';
import { getFormattedTime } from '../../../shared/utils/getFormattedTime';
import { JSONSchema } from '../../../shared/utils/jsonschema';
import { InternalDashboardQuestionnaire, InternalDashboardQuestionnaireRes } from './type';

const WorkspaceSchema: JSONSchema = {
	type: 'object',
	properties: {
		id: { type: 'string' },
		name: { type: 'string' },
	},
};

const BrandSchema: JSONSchema = {
	type: 'object',
	properties: {
		id: { type: 'string' },
		name: { type: 'string' },
		workspace: WorkspaceSchema,
	},
};

const CreatorSchema: JSONSchema = {
	type: 'object',
	properties: {
		id: { type: 'string' },
		firstName: { anyOf: [{ type: 'string' }, { type: 'object', value: null }] },
		lastName: { anyOf: [{ type: 'string' }, { type: 'object', value: null }] },
		email: { anyOf: [{ type: 'string' }, { type: 'object', value: null }] },
		locusId: { type: 'string' },
		companyName: { anyOf: [{ type: 'string' }, { type: 'object', value: null }] },
		phoneNumber: { anyOf: [{ type: 'string' }, { type: 'object', value: null }] },
		profilePicture: { anyOf: [{ type: 'string' }, { type: 'object', value: null }] },
	},
};

const ResearchScheme: JSONSchema = {
	type: 'object',
	properties: {
		id: { type: 'string' },
		researchModule: { type: 'string' },
		researchType: { type: 'string' },
	},
};

const QuestionsScheme: JSONSchema = {
	type: 'array',
};

const SurveySchema: JSONSchema = {
	type: 'array',
	items: {
		type: 'object',
		properties: {
			id: { type: 'string' },
			orderPosition: { type: 'number' },
			questionnaireId: { type: 'string' },
			questions: QuestionsScheme,
			research: ResearchScheme,
		},
	},
};

const NicheScheme: JSONSchema = {
	type: 'array',
	items: {
		type: 'object',
		properties: {
			id: { type: 'string' },
			country: { anyOf: [{ type: 'string' }, { type: 'object', value: null }] },
			region: { anyOf: [{ type: 'string' }, { type: 'object', value: null }] },
			city: { anyOf: [{ type: 'string' }, { type: 'object', value: null }] },
			ageFrom: { anyOf: [{ type: 'number' }, { type: 'object', value: null }] },
			ageTo: { anyOf: [{ type: 'number' }, { type: 'object', value: null }] },
			gender: { anyOf: [{ type: 'string' }, { type: 'object', value: null }] },
			incomeFrom: { anyOf: [{ type: 'number' }, { type: 'object', value: null }] },
			incomeTo: { anyOf: [{ type: 'number' }, { type: 'object', value: null }] },
			interests: { type: 'array', items: { type: 'string' } },
			name: { type: 'string' },
			relationshipStatuses: { type: 'array', items: { type: 'string' } },
			macroTargetingGroupId: { type: 'string' },
			languages: {
				anyOf: [
					{ type: 'array', items: { type: 'string' } },
					{ type: 'object', value: null },
				],
			},
			deviceType: {
				anyOf: [{ type: 'string' }, { type: 'object', value: null }],
			},
			industries: {
				anyOf: [
					{ type: 'array', items: { type: 'string' } },
					{ type: 'object', value: null },
				],
			},
			employmentStatuses: {
				anyOf: [
					{ type: 'array', items: { type: 'string' } },
					{ type: 'object', value: null },
				],
			},
			jobRoles: {
				anyOf: [
					{ type: 'array', items: { type: 'string' } },
					{ type: 'object', value: null },
				],
			},
			expertiseLevel: {
				anyOf: [{ type: 'string' }, { type: 'object', value: null }],
			},
			totalParticipants: { anyOf: [{ type: 'number' }, { type: 'object', value: null }, { type: 'undefined' }] },
			totalExperts: { anyOf: [{ type: 'number' }, { type: 'object', value: null }, { type: 'undefined' }] },
			numberOfRespondents: { anyOf: [{ type: 'number' }, { type: 'object', value: null }] },
			socialNetworks: {
				anyOf: [
					{ type: 'array', items: { type: 'string' } },
					{ type: 'object', value: null },
				],
			},
			isSelected: { anyOf: [{ type: 'boolean' }, { type: 'object', value: null }] },
			isUsingPyxisSmartPanel: { anyOf: [{ type: 'boolean' }, { type: 'object', value: null }] },
			audiencePanel: {
				anyOf: [
					{ type: 'array', items: { type: 'string' } },
					{ type: 'object', value: null },
				],
			},
		},
	},
};

const MacroTargetingSchema: JSONSchema = {
	type: 'object',
	properties: {
		id: { type: 'string' },
		country: { type: 'string' },
		region: { anyOf: [{ type: 'string' }, { type: 'object', value: null }] },
		city: { anyOf: [{ type: 'string' }, { type: 'object', value: null }] },
		ageFrom: { anyOf: [{ type: 'number' }, { type: 'object', value: null }] },
		ageTo: { anyOf: [{ type: 'number' }, { type: 'object', value: null }] },
		gender: { anyOf: [{ type: 'string' }, { type: 'object', value: null }] },
		niches: NicheScheme,
	},
};

export const QuestionnaireSchema: JSONSchema = {
	type: 'object',
	properties: {
		id: { type: 'string' },
		name: { anyOf: [{ type: 'string' }, { type: 'object', value: null }] },
		allowMultipleResponse: { type: 'boolean' },
		description: { anyOf: [{ type: 'string' }, { type: 'object', value: null }] },
		brand: BrandSchema,
		creator: CreatorSchema,
		macroTargetingGroup: MacroTargetingSchema,
		createdAt: { anyOf: [{ type: 'string' }, { type: 'object', value: null }] },
		startTime: { anyOf: [{ type: 'string' }, { type: 'object', value: null }] },
		endTime: { anyOf: [{ type: 'string' }, { type: 'object', value: null }] },
		publishTime: { anyOf: [{ type: 'string' }, { type: 'object', value: null }] },
		cancelTime: { anyOf: [{ type: 'string' }, { type: 'object', value: null }] },
		testingTargetUrls: {
			anyOf: [
				{ type: 'array', items: { type: 'string' } },
				{ type: 'object', value: null },
			],
		},
		testingGoal: { anyOf: [{ type: 'string' }, { type: 'object', value: null }] },
		status: { type: 'string' },
		surveys: SurveySchema,
	},
};

export const prepareQuestionnaire = (item: InternalDashboardQuestionnaireRes): InternalDashboardQuestionnaire => ({
	id: item.id,
	name: item.name,
	description: item.description,
	allowMultipleResponse:item.allowMultipleResponse,
	pixelId: item.pixelId,
	macroTargetingGroup: {
		...omit(['niches', 'ageTo', 'ageFrom'], item.macroTargetingGroup),
		createdAt: getFormattedTime(item.macroTargetingGroup.createdAt),
		updatedAt: getFormattedTime(item.macroTargetingGroup.updatedAt),
		age: `${item.macroTargetingGroup.ageFrom}-${item.macroTargetingGroup.ageTo}`,
	},
	workspace: {
		id: item.brand.workspace.id,
		name: item.brand.workspace.name,
	},
	brand: {
		id: item.brand.id,
		name: item.brand.name,
	},
	creator: item.creator,
	paymentStatus: item.paymentStatus,
	createdAt: getFormattedTime(item.createdAt),
	startTime: getFormattedTime(item.startTime),
	endTime: getFormattedTime(item.endTime),
	publishTime: getFormattedTime(item.publishTime),
	cancelTime: getFormattedTime(item.cancelTime),
	status: item.status,
	surveys: item.surveys,
	questionnaireNiches: item.macroTargetingGroup.niches.reduce((acc, niche) => {
		return niche.isSelected && niche.createdAt && niche.updatedAt
			? [
					...acc,
					{
						...omit(['ageTo', 'ageFrom', 'incomeTo', 'incomeFrom'], niche),
						age: `${niche.ageFrom}-${niche.ageTo}`,
						income: `${niche.incomeFrom}-${niche.incomeTo}`,
						createdAt: getFormattedTime(niche.createdAt),
						updatedAt: getFormattedTime(niche.updatedAt),
					},
			  ]
			: acc;
	}, [] as Niche[]),

	testingGoal: item.testingGoal,
	testingTargetUrls: item.testingTargetUrls,
	isModerated: item.isModerated,
});
