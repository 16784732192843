import {
	EntitiesReducer,
	SurveyResponsesAction,
	READ_RESPONSES_FULFILLED,
	PATCH_RESPONSE_FULFILLED,
	PATCH_RESPONSES_FULFILLED,
} from './types';

export const surveyResponseReducer: EntitiesReducer = (state, action: SurveyResponsesAction) => {
	switch (action.type) {
		case READ_RESPONSES_FULFILLED:
			return {
				...state,
				...action.payload,
			};
		case PATCH_RESPONSE_FULFILLED:
			return {
				...state,
				entities: state.entities.map((response) =>
					response._id === action.payload.responseId ? { ...response, approved: action.payload.approved } : response
				),
			};
		case PATCH_RESPONSES_FULFILLED:
			return {
				...state,
				entities: state.entities.map((response) => ({ ...response, approved: true })),
			};
		default:
			return state;
	}
};
