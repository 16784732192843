import axios from '@utils/axios';
import { takeLeading } from 'redux-saga/effects';
import { getApiEndPointUrl } from '../../shared/utils/getApiEndpointUrl';
import { getApiError } from '../../shared/utils/getApiError';
import { validate } from '../../shared/utils/jsonschema';
import { putEffect } from '../../shared/utils/putEffect';
import { pend, fulfill, reject } from '../promise/actions';
import {
	readCustomVariables,
	readCustomVariablesFulfilled,
	updateCustomVariableFulfilled,
	updateCustomVariable,
} from './actions';
import { surveyEndPoint, CustomVariablesEndPoint } from './const';
import {
	READ_CUSTOM_VARIABLES,
	ReadCustomVariables,
	CustomVariablesSchema,
	UpdateCustomVariablePayload,
	UpdateCustomVariables,
	UPDATE_CUSTOM_VARIABLES,
} from './types';
function readCustomVariablesAPI(id: string) {
	return axios.get(getApiEndPointUrl(surveyEndPoint, id, CustomVariablesEndPoint));
}

function updateCustomVariableAPI({ variables, surveyId }: UpdateCustomVariablePayload) {
	return axios.put(getApiEndPointUrl(surveyEndPoint, surveyId, CustomVariablesEndPoint), { variables });
}

function* updateCustomVariableSaga(action: UpdateCustomVariables) {
	try {
		yield putEffect(pend(updateCustomVariable));
		yield updateCustomVariableAPI(action.payload);
		yield putEffect(updateCustomVariableFulfilled(action.payload));
		yield putEffect(fulfill(updateCustomVariable));
	} catch (e) {
		yield putEffect(reject(updateCustomVariable, getApiError(e)));
	}
}

function* readCustomVariablesSaga(action: ReadCustomVariables) {
	try {
		yield putEffect(pend(readCustomVariables));
		const res = yield readCustomVariablesAPI(action.payload);

		yield putEffect(readCustomVariablesFulfilled(validate(res.data, CustomVariablesSchema)));
		yield putEffect(fulfill(readCustomVariables));
	} catch (e) {
		yield putEffect(reject(readCustomVariables, getApiError(e)));
	}
}

export const getCustomVariablesWatchers = [
	function* () {
		yield takeLeading(READ_CUSTOM_VARIABLES, readCustomVariablesSaga);
	},
	function* () {
		yield takeLeading(UPDATE_CUSTOM_VARIABLES, updateCustomVariableSaga);
	},
];
