import { getApiEndPointUrl } from '../../shared/utils/getApiEndpointUrl';
import { quickFetch } from '../../utils/fetch';
import { useMutation, UseMutationOptions, useQuery, useQueryClient } from 'react-query';

export const getUserProfileEndPoint = 'profiles/me';

export type ProfileState = {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
	companyName: string;
	phoneNumber: string;
	isActive: boolean;
	profilePicture: string;
	currentPassword: string;
	newPassword: string;
	pictureFormData: File | null;
	countryCode: string;
	notification: string | null;
	confirmNewPassword: string;
	currentWorkspaceId: string | null;
	locusId: string;
};
export const useReadProfile = (options: any = {}) => {
	return useQuery<ProfileState, any, ProfileState>(
		['readProfile'],
		() => quickFetch(getApiEndPointUrl(getUserProfileEndPoint)),
		{
			retry: (_, error) => {
				if (error.status === 401) return false;
				return true;
			},
			...options,
		}
	);
};
export const usePutProfile = () => {
	const queryClient = useQueryClient();
	return useMutation<ProfileState, any, Partial<ProfileState>>(
		(body: any) => quickFetch(getApiEndPointUrl(getUserProfileEndPoint, 'current-workspace'), { body, method: 'put' }),
		{
			onSuccess: (_, variables: any) => {
				queryClient.setQueryData(['readProfile'], (data) => ({ ...(data as ProfileState), ...variables }));
			},
		}
	);
};
export const usePatchProfile = (options: UseMutationOptions<ProfileState, any, Partial<ProfileState>> = {}) => {
	const queryClient = useQueryClient();
	return useMutation(
		(body: any) => quickFetch<ProfileState>(getApiEndPointUrl(getUserProfileEndPoint), { body, method: 'PATCH' }),
		{
			...options,
			onSuccess: (received, ...rest) => {
				queryClient.setQueryData(['readProfile'], (data) => received as ProfileState);
				options.onSuccess?.(received, ...rest);
			},
		}
	);
};
