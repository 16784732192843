import { ReduxAction } from '../../../shared/types/ReduxAction';
import { ReduxReducer } from '../../../shared/types/ReduxReducer';
import { WorkspaceState } from '../types';

export type Role = {
	id: string;
	name: string;
	permissions: {
		action: string;
		groupLabel: string;
		id: string;
		label: string;
		entityType: string;
	}[];
};

export const READ_WORKSPACE_ROLES = 'READ_WORKSPACE_ROLES';
export type ReadWorkspaceRoles = ReduxAction<typeof READ_WORKSPACE_ROLES, string>;
export const READ_WORKSPACE_ROLES_FULFILLED = 'READ_WORKSPACE_ROLES_FULFILLED';
export type ReadWorkspaceRolesFulfilled = ReduxAction<typeof READ_WORKSPACE_ROLES_FULFILLED, Role[]>;

export type WorkspaceAction = ReadWorkspaceRoles | ReadWorkspaceRolesFulfilled;

export type RoleReducer = ReduxReducer<WorkspaceState, WorkspaceAction>;
