export const decamelize = (str: string, separator: string) => {
	return str
		.replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2')
		.replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2');
};

export const camelize = (str: string) => {
	return str
		.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
			return index === 0 ? word.toLowerCase() : word.toUpperCase();
		})
		.replace(/\s+/g, '');
};

export const toSentence = (val: string): string => {
	return val.slice(0, 1).toUpperCase() + decamelize(val.slice(1), ' ').toLowerCase();
};
export const toTitleCase = (val: string): string => {
	return val.slice(0, 1).toUpperCase() + decamelize(val.slice(1), ' ');
};
