import { ReduxAction } from '../../../shared/types/ReduxAction';
import { ReduxReducer } from '../../../shared/types/ReduxReducer';
import { WorkspaceState } from '../types';

export type User = {
	firstName: string;
	lastName: string;
	roleId: string;
	email: string;
};

export const CREATE_WORKSPACE_USERS = 'CREATE_WORKSPACE_USERS';
export type CreateWorkspaceUsers = ReduxAction<typeof CREATE_WORKSPACE_USERS, User[]>;
export const CREATE_WORKSPACE_USERS_FULFILLED = 'CREATE_WORKSPACE_USERS_FULFILLED';
export type CreateWorkspaceUsersFulfilled = ReduxAction<typeof CREATE_WORKSPACE_USERS_FULFILLED, User[]>;

export type WorkspaceAction = CreateWorkspaceUsers | CreateWorkspaceUsersFulfilled;

export type UserReducer = ReduxReducer<WorkspaceState, WorkspaceAction>;
