import { quickFetch } from './fetch';

type Params = Record<any, any>;
type RequestData = Record<string, any> | FormData | undefined;

type GenericOptions = {
	params?: Params;
};

class Axios {
	async get(url: string, options: GenericOptions = {}) {
		const { params = {} } = options;
		const data: any = await quickFetch(url, { params, method: 'GET' });
		return { data };
	}

	async post(url: string, body: RequestData = {}, options: GenericOptions = {}) {
		const { params = {} } = options;
		const data: any = await quickFetch(url, { params, body, method: 'POST' });
		return { data };
	}

	async put(url: string, body: RequestData = {}, options: GenericOptions = {}) {
		const { params = {} } = options;
		const data: any = await quickFetch(url, { params, body, method: 'PUT' });
		return { data };
	}

	async patch(url: string, body: RequestData = {}, options: GenericOptions = {}) {
		const { params = {} } = options;
		const data: any = await quickFetch(url, { params, body, method: 'PATCH' });
		return { data };
	}

	async delete(url: string, options: GenericOptions = {}) {
		const { params = {} } = options;
		const data: any = await quickFetch(url, { params, method: 'DELETE' });
		return { data };
	}
}

const axios = new Axios();

export default axios;
