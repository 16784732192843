import axios from '@utils/axios';
import { putEffect } from '../../shared/utils/putEffect';
import { CreateUsersAction, CREATE_USERS, UserData } from './types';
import { rejected, pending, fulfilled } from './actions';
import { getApiError } from '../../shared/utils/getApiError';
import { call, takeLatest } from '@redux-saga/core/effects';
import { getApiEndPointUrl } from '../../shared/utils/getApiEndpointUrl';
import { RequestStatus } from '../../shared/enums/RequestStatus';

const createUsersRequest = (users: UserData[], url: string) => axios.post(url, users);
export const createUserEndPoint = 'endpointGoesHere';

function* createUsersSaga(action: CreateUsersAction) {
	try {
		yield putEffect(pending(RequestStatus.Pending));
		yield call(
			createUsersRequest,
			action.payload.users,
			getApiEndPointUrl(createUserEndPoint, action.payload.workspaceId)
		);
		yield putEffect(fulfilled(RequestStatus.Fulfilled));
	} catch (e) {
		yield putEffect(rejected({ status: RequestStatus.Rejected, error: getApiError(e) }));
	}
}

function* createUserWatcher() {
	yield takeLatest(CREATE_USERS, createUsersSaga);
}

export const createUserSagas = [createUserWatcher];
