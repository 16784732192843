import { SurveyStatus } from '../enums/SurveyStatus';

export const routePaths = {
	home: {
		root: '/',
		sub: {
			dashboard: '/',
		},
	},
	surveys: {
		root: '/surveys',
		sub: {
			active: `/${SurveyStatus.Active}`,
			completed: `/${SurveyStatus.Completed}`,
			scheduled: `/${SurveyStatus.Scheduled}`,
			cancellation: `/${SurveyStatus.InCancellation}`,
			review: `/${SurveyStatus.InReview}`,
			approved: `/${SurveyStatus.Approved}`,
		},
	},
	auth: {
		root: '/auth',
		sub: {
			login: '/sign-in',
			register: '/sign-up',
			resetPassword: '/reset-password',
		},
	},
	users: {
		root: '/users',
		sub: {
			dashboard: '/',
			onboard: '/onboard',
			invite: '/add-user',
			inviteSuccess: '/invite-success',
		},
	},
	questionnaire: {
		root: '/questionnaire',
		sub: {
			view: '/:questionnaireId/details',
			macro: '/:questionnaireId/macro-targeting-groups',
			responses: '/:questionnaireId/responses',
			niches: '/:questionnaireId/niches',
			niche: '/:nicheId',
			timeslot: '/:nicheId/timeslot',
			approval: '/:nicheId/approval',
			variables: '/:questionnaireId/add-variables',
			redirectURLs: '/:questionnaireId/redirect-urls',
			setQuota: '/:questionnaireId/set-quota',
			allQuotas: '/all-quotas',
			editQuotas: '/edit-quotas',
			pixelIntegration: '/:questionnaireId/pixel-integration',
		},
	},
	workspaces: {
		root: '/workspace',
		sub: {
			dashboard: '/',
		},
	},
	creators: {
		root: '/creators',
		sub: {
			dashboard: "/"
		},
	},
} as const;