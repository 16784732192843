import { ReduxAction } from '../../../shared/types/ReduxAction';
import { ReduxReducer } from '../../../shared/types/ReduxReducer';
import { JSONSchema } from '../../../shared/utils/jsonschema';
import { InternalDashboardQuestionnaire } from '../questionnaires/type';
import { SurveyStatus } from '../../../shared/enums/SurveyStatus';

export type InternalDashboardResponseItem = {
	name: string;
	startTime: string;
	endTime: string;
	respondentCount: number;
	brand: {
		id: string;
		name: string;
		workspace: { id: string; name: string };
	};
	creator: {
		id: string;
		firstName: string;
		lastName: string;
		email: string;
	};
	createdAt: string;
	publishTime: string | null;
	id: string;
	paymentStatus: string;
	status: string;
	testingTargetUrls: string[];
	testingGoal: string;
	pixelId: string | null;
};

export type InternalDashboardItem = {
	name: string;
	startTime: string;
	endTime: string;
	respondentCount: number;
	brandId: string;
	firstName: string;
	lastName: string;
	email: string;
	brandName: string;
	workspaceId: string;
	workspaceName: string;
	surveyNumber: number;
	paymentStatus: string;
	id: string;
	status: string;
	testingTargetUrls: string[];
	testingGoal: string;
	publishedAt: string | null;
};

export type Survey = {
	id: string;
	totalRespondents: number;
	orderPosition: number;
	questionnaireId: string;
	credits: number;
	instruction: Array<{
		id: string;
		instruction: string[];
	}>;
	research: {
		id: string;
		researchModule: string;
		researchType: string;
		subResearch: string[];
	};
};

const WorkspaceSchema: JSONSchema = {
	type: 'object',
	properties: { id: { type: 'string' }, name: { type: 'string' } },
};

const BrandSchema: JSONSchema = {
	type: 'object',
	properties: {
		id: { type: 'string' },
		name: { type: 'string' },
		workspace: WorkspaceSchema,
	},
};

export const InternalDasboardItemSchema: JSONSchema = {
	type: 'object',
	properties: {
		name: { type: 'string' },
		startTime: { type: 'string' },
		endTime: { type: 'string' },
		respondentCount: { type: 'number' },
		brand: BrandSchema,
	},
};

export const InternalDasboardItemsSchema: JSONSchema = {
	type: 'array',
	items: InternalDasboardItemSchema,
};

export type InternalDashboardState = {
	items: InternalDashboardItem[];
	questionnaire: InternalDashboardQuestionnaire | null | any;
	count: number | null;
};

export const READ_SURVEYS = 'READ_SURVEYS';
export type ReadSurveysPayload = {
	limit?: number;
	offset?: number;
	status?: SurveyStatus;
};
export type ReadSurveysAction = ReduxAction<typeof READ_SURVEYS, ReadSurveysPayload>;

export const READ_SURVEYS_FULFILLED = 'READ_SURVEYS_FULFILLED';
export type ReadSurveysFulfilledPayload = { count: number; items: InternalDashboardItem[] };
export type ReadSurveysFulfilledAction = ReduxAction<typeof READ_SURVEYS_FULFILLED, ReadSurveysFulfilledPayload>;

export const UPDATE_SURVEY_INSTRUCTION = 'UPDATE_SURVEY_INSTRUCTION';
export type UpdateSurveyInstructionPayload = {
	surveyId: string;
	instruction: Array<{
		id?: string;
		instruction: string[];
	}>;
};
export type UpdateSurveyInstruction = ReduxAction<typeof UPDATE_SURVEY_INSTRUCTION, UpdateSurveyInstructionPayload>;

export const UPDATE_SURVEY_INSTRUCTION_FULFILLED = 'UPDATE_SURVEY_INSTRUCTION_FULFILLED';
export type UpdateSurveyInstructionFulfilled = ReduxAction<
	typeof UPDATE_SURVEY_INSTRUCTION_FULFILLED,
	UpdateSurveyInstructionPayload
>;

export type InternalDashboardAction =
	| ReadSurveysAction
	| ReadSurveysFulfilledAction
	| UpdateSurveyInstruction
	| UpdateSurveyInstructionFulfilled;

export type InternalDashboardReducer = ReduxReducer<InternalDashboardState, InternalDashboardAction>;
