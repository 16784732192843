import {
	ResponsesVisibiltyReducer,
	ResponsesVisibiltyAction,
	READ_RESPONSES_VISIBILITY_FULFILLED,
	PUT_RESPONSES_VISIBILITY_FULFILLED,
	DELETE_RESPONSES_VISIBILITY_FULFILLED,
} from './types';

export const responsesVisibiltyReducer: ResponsesVisibiltyReducer = (state, action: ResponsesVisibiltyAction) => {
	switch (action.type) {
		case READ_RESPONSES_VISIBILITY_FULFILLED:
			return {
				...state,
				visibility: action.payload,
			};
		case PUT_RESPONSES_VISIBILITY_FULFILLED:
			return {
				...state,
				visibility: true,
			};
		case DELETE_RESPONSES_VISIBILITY_FULFILLED:
			return {
				...state,
				visibility: false,
			};
		default:
			return state;
	}
};
