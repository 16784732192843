import { AuthActionError } from 'shared/types/ResponseError';

export type ApiError = {
	message: {
		payload: {
			message: string;
			fields: AuthActionError;
		};
	};
};

export const getApiError = <T extends ApiError>(e: T): string => {
	const fields = e?.message?.payload?.fields;
	return (fields?.length
		? Array.isArray(fields[0].error)
			? fields[0].error[0]
			: fields[0].error
		: e?.message?.payload?.message || 'Something went wrong') as string;
};

export const getApiErrorFields = <T extends ApiError>(e: T): string => {
	return (e?.message?.payload?.fields?.length
		?e?.message?.payload?.fields
		: e?.message?.payload?.message  || 'Something went wrong') as string;
};
