import { InternalDashboardItem, InternalDashboardResponseItem } from './types';
import { getFormattedTime } from '../../../shared/utils/getFormattedTime';

const prepareItem = (item: InternalDashboardResponseItem, index: number): InternalDashboardItem => ({
	startTime: getFormattedTime(item.startTime),
	endTime: getFormattedTime(item.endTime),
	brandId: item.brand.id,
	brandName: item.brand.name,
	workspaceId: item.brand.workspace.id,
	workspaceName: item.brand.workspace.name,
	name: item.name,
	firstName: item.creator.firstName,
	lastName: item.creator.lastName,
	email: item.creator.email,
	respondentCount: item.respondentCount,
	paymentStatus: item.paymentStatus,
	surveyNumber: index + 1,
	publishedAt: getFormattedTime(item.publishTime),
	id: item.id,
	status: item.status,
	testingTargetUrls: item.testingTargetUrls,
	testingGoal: item.testingGoal,
});

export const prepareData = (items: InternalDashboardResponseItem[]): InternalDashboardItem[] => {
	return items.map(prepareItem);
};
