import {
	InternalDashboardState,
	InternalDashboardReducer,
	READ_SURVEYS_FULFILLED,
	InternalDashboardAction,
	UPDATE_SURVEY_INSTRUCTION_FULFILLED,
} from './types';
import { reduceReducers } from '../../../shared/utils/reduceReducers';
import { questionnairesReducer } from '../questionnaires/reducer';

export const initialState: InternalDashboardState = {
	items: [],
	questionnaire: null,
	count: null,
};
const internalDashboardReducer: InternalDashboardReducer = (state, action) => {
	switch (action.type) {
		case READ_SURVEYS_FULFILLED:
			return {
				...state,
				...action.payload,
			};
		case UPDATE_SURVEY_INSTRUCTION_FULFILLED:
			const updatedState = JSON.parse(JSON.stringify(state));
			updatedState.questionnaire.surveys[0].instruction = action.payload.instruction;
			return updatedState;
		default:
			return state;
	}
};

export const internalDashboardSharedReducer = reduceReducers<InternalDashboardState, InternalDashboardAction>(
	initialState,
	questionnairesReducer,
	internalDashboardReducer
);
