const timeFormat = {
	hour: '2-digit',
	minute: '2-digit',
	day: 'numeric',
	month: 'short',
	year: 'numeric',
} as Intl.DateTimeFormatOptions;
const defaultLocale = 'en-US';

export const getFormattedTime = (dateString: string | null) =>
	dateString ? new Date(dateString).toLocaleTimeString(defaultLocale, timeFormat) : 'Not Available';

const dateFormat = {
	day: 'numeric',
	month: 'short',
} as Intl.DateTimeFormatOptions;

export const getFormattedDate = (dateString: string | null) =>
	dateString
		? new Date(dateString)
				.toLocaleTimeString(defaultLocale, dateFormat)
				.split(', ')
				.filter(Boolean)[0]
				.split(' ')
				.reverse()
				.join(' ')
		: 'Not Available';

export const getDateDDMMYY = (dateString: string | null) => {
	if (!dateString) return 'Not Available';
	const date = new Date(dateString);

	const year = date.getFullYear();

	let month = (1 + date.getMonth()).toString();
	month = month.length > 1 ? month : '0' + month;

	let day = date.getDate().toString();
	day = day.length > 1 ? day : '0' + day;

	return day + '/' + month + '/' + year;
};
