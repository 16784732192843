import {
	DeleteResponsesVisibilty,
	DeleteResponsesVisibiltyFulfilled,
	DELETE_RESPONSES_VISIBILITY,
	DELETE_RESPONSES_VISIBILITY_FULFILLED,
	PutResponsesVisibilty,
	PutResponsesVisibiltyFulfilled,
	PUT_RESPONSES_VISIBILITY,
	PUT_RESPONSES_VISIBILITY_FULFILLED,
	ReadResponsesVisibilty,
	ReadResponsesVisibiltyFulfilled,
	READ_RESPONSES_VISIBILITY,
	READ_RESPONSES_VISIBILITY_FULFILLED,
} from './types';

export const readResponsesVisibilty = (payload: {
	questionnaireId: string;
	nicheId: Array<string>;
}): ReadResponsesVisibilty => ({
	type: READ_RESPONSES_VISIBILITY,
	payload,
});

export const readResponsesVisibiltyFulfilled = (payload: boolean): ReadResponsesVisibiltyFulfilled => ({
	type: READ_RESPONSES_VISIBILITY_FULFILLED,
	payload: payload,
});

export const putResponsesVisibilty = (payload: {
	questionnaireId: string;
	nicheId: Array<string>;
}): PutResponsesVisibilty => ({
	type: PUT_RESPONSES_VISIBILITY,
	payload,
});

export const putResponsesVisibiltyFulfilled = (payload: {
	questionnaireId: string;
	nicheId: Array<string>;
}): PutResponsesVisibiltyFulfilled => ({
	type: PUT_RESPONSES_VISIBILITY_FULFILLED,
	payload: payload,
});

export const deleteResponsesVisibilty = (payload: {
	questionnaireId: string;
	nicheId: Array<string>;
}): DeleteResponsesVisibilty => ({
	type: DELETE_RESPONSES_VISIBILITY,
	payload,
});

export const deleteResponsesVisibiltyFulfilled = (payload: {
	questionnaireId: string;
	nicheId: Array<string>;
}): DeleteResponsesVisibiltyFulfilled => ({
	type: DELETE_RESPONSES_VISIBILITY_FULFILLED,
	payload: payload,
});
