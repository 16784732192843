import cloneDeep from 'lodash.clonedeep';
import { SET_WORKSPACES, UPDATE_WORKSPACE_FULFILLED, WorkspaceReducer } from './types';

export const workspaceDetailReducer: WorkspaceReducer = (state, action) => {
	switch (action.type) {
		case SET_WORKSPACES:
			return { ...state, workspaces: action.payload.items, workspacesCount: action.payload.count };
		case UPDATE_WORKSPACE_FULFILLED: {
			const { updates, workspaceId } = action.payload;

			const workspaces = cloneDeep(state.workspaces);
			const workspaceIndex = workspaces.findIndex((w) => w.id === workspaceId);
			if (workspaceIndex !== -1) {
				workspaces[workspaceIndex] = Object.assign({}, workspaces[workspaceIndex], updates);
			}

			return {
				...state,
				workspaces,
			};
		}
		default:
			return state;
	}
};
