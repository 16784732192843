import {
	CreateUsersPendingAction,
	CREATE_USERS_PENDING,
	CREATE_USERS_FULFILLED,
	CREATE_USERS_REJECTED,
	CreateUsersFulfilledAction,
	CreateUsersRejected,
	CREATE_USERS,
	CreateUsersAction,
	CreateUsersPayload,
	AddUserError,
	RESET_CREATE_USERS_STATE,
	ResetUsersAction,
} from './types';
import { RequestStatus } from '../../shared/enums/RequestStatus';

export const createUsersAction: (users: CreateUsersPayload) => CreateUsersAction = (payload) => ({
	type: CREATE_USERS,
	payload,
});

export const pending: (status: RequestStatus.Pending) => CreateUsersPendingAction = (payload) => ({
	type: CREATE_USERS_PENDING,
	payload,
});

export const fulfilled: (status: RequestStatus.Fulfilled) => CreateUsersFulfilledAction = (payload) => ({
	type: CREATE_USERS_FULFILLED,
	payload,
});

export const rejected: (err: AddUserError) => CreateUsersRejected = (payload) => ({
	type: CREATE_USERS_REJECTED,
	payload,
});

export const reset: (status: RequestStatus.Idle) => ResetUsersAction = (payload) => ({
	type: RESET_CREATE_USERS_STATE,
	payload,
});
