import { takeLatest } from '@redux-saga/core/effects';
import { putEffect } from '../../../shared/utils/putEffect';
import axios from '@utils/axios';
import { getApiEndPointUrl } from '../../../shared/utils/getApiEndpointUrl';
import { getApiError } from '../../../shared/utils/getApiError';
import { fulfill, pend, reject } from '../../promise/actions';
import { CreateWorkspaceUsers, CREATE_WORKSPACE_USERS } from './types';
import { createWorkspaceUsers, createWorkspaceUsersFulfilled } from './action';

const createWorkspaceUsersEndPoint = 'workspace-users/batch/admin';

function* createWorkspaceUsersSaga({ payload }: CreateWorkspaceUsers) {
	try {
		yield putEffect(pend(createWorkspaceUsers));
		const res = yield axios.post(getApiEndPointUrl(createWorkspaceUsersEndPoint), {
			usersList: payload,
			customVariables: {
				productUrl: window.location.protocol + '//' + window.location.hostname,
			},
		});
		yield putEffect(createWorkspaceUsersFulfilled(res.data));
		yield putEffect(fulfill(createWorkspaceUsers));
	} catch (e) {
		yield putEffect(reject(createWorkspaceUsers, getApiError(e)));
	}
}

export const usersSagas = [
	function* () {
		yield takeLatest(CREATE_WORKSPACE_USERS, createWorkspaceUsersSaga);
	},
];
