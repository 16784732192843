import { Drawer, DrawerProps } from 'pyxis-ui-kit';
import React, { FC, ReactNode } from 'react';
import {
	DrawerImage,
	DrawerSubTitle,
	DrawerTitle,
	StyledButton,
	StyledCancelButton,
	StyledDrawerSpace,
	StyledDrawerText,
	StyledFooter,
	StyledLeftOutlined,
	StyledRightOutlined,
} from './styled';
import successSvg from '../../../assets/svg/drawerSuccess.svg';
import { theme } from '../../consts/theme';

export type ConfirmDrawerProps = DrawerProps & {
	title?: string;
	subTitle?: string;
	confirmContent?: string | ReactNode;
	denyContent?: string | ReactNode;
	confirmButton?: ReactNode;
	denyButton?: ReactNode;
	banner?: string;
	onConfirm?: Function;
	onDeny?: Function;
};

const ConfirmDrawer: FC<ConfirmDrawerProps> = ({
	title = 'Are you sure?',
	subTitle = '',
	onDeny = () => {}, // not works with footer or declineButton
	onConfirm = () => {}, // not works with footer or confirmButton
	footer,
	confirmContent = 'Continue', // not works with footer or confirmButton, pass null to remove
	denyContent = 'Cancel', // not works with footer or with denyButton, pass null to remove
	banner = successSvg,
	confirmButton,
	denyButton,
	...props
}) => {
	const finalConfirmButton =
		confirmButton === undefined ? (
			<StyledButton onClick={() => onConfirm()}>
				{confirmContent}
				<StyledRightOutlined />
			</StyledButton>
		) : (
			confirmButton
		);

	const finalDenyButton =
		denyButton === undefined ? (
			<StyledCancelButton onClick={() => onDeny()}>
				<StyledLeftOutlined />
				{denyContent}
			</StyledCancelButton>
		) : (
			denyButton
		);

	const finalFooter =
		footer === undefined ? (
			<StyledFooter>
				{finalDenyButton}
				<span /> {/* Keeps button aligned even if one component is null*/}
				{finalConfirmButton}
			</StyledFooter>
		) : (
			footer
		);
	return (
		<Drawer
			zIndex={theme.layouts.zIndex.drawer}
			width="32.41rem"
			{...props}
			footer={finalFooter}
			bodyStyle={{
				background: 'white',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				textAlign: 'center',
			}}
			// visible={true}
		>
			<DrawerImage src={banner} />
			<StyledDrawerSpace />
			<StyledDrawerText>
				<DrawerTitle>{title}</DrawerTitle>
				<DrawerSubTitle>{subTitle}</DrawerSubTitle>
			</StyledDrawerText>
		</Drawer>
	);
};

export default ConfirmDrawer;
