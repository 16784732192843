import {
	ReadResponses,
	READ_RESPONSES,
	ReadResponsesFulfilled,
	READ_RESPONSES_FULFILLED,
	PatchResponse,
	PATCH_RESPONSES,
	PatchResponseFulfilled,
	PATCH_RESPONSE_FULFILLED,
	PatchResponses,
	PatchResponsesFulfilled,
	PATCH_RESPONSE,
	PATCH_RESPONSES_FULFILLED,
	ResponseEntities,
	PatchResponsePayload,
	PatchResponsesPayload,
	ReadResponsesPayload,
	UPDATE_VIDEO_MARKERS,
	UpdateVideoMarkersPayload,
	UpdateVideoMarkers,
} from './types';

export const readResponses = (payload: ReadResponsesPayload): ReadResponses => ({
	type: READ_RESPONSES,
	payload,
});

export const readResponsesFulfilled = (payload: ResponseEntities): ReadResponsesFulfilled => ({
	type: READ_RESPONSES_FULFILLED,
	payload: payload,
});
export const patchResponse = (payload: PatchResponsePayload): PatchResponse => ({
	type: PATCH_RESPONSE,
	payload: payload,
});
export const patchResponseFulfilled = (payload: {
	questionnaireId: string;
	responseId: string;
	approved: boolean;
	successMessage: String;
	readResonsePayload: Object;
	isApproveAll?: boolean;
	data?: any;
}): PatchResponseFulfilled => ({
	type: PATCH_RESPONSE_FULFILLED,
	payload: payload,
});
export const patchResponses = (payload: PatchResponsesPayload): PatchResponses => ({
	type: PATCH_RESPONSES,
	payload: payload,
});
export const patchResponsesFulfilled = (): PatchResponsesFulfilled => ({
	type: PATCH_RESPONSES_FULFILLED,
	payload: null,
});
export const updateVideoMarkers = (payload: UpdateVideoMarkersPayload): UpdateVideoMarkers => ({
	type: UPDATE_VIDEO_MARKERS,
	payload,
});
