import { takeLatest } from '@redux-saga/core/effects';
import { putEffect } from '../../../shared/utils/putEffect';
import { getWorkspaceAction, setWorkspacesAction, updateWorkspaceAction, updateWorkspaceFulfilled } from './action';
import {
	GetWorkspacesAction,
	GET_WORKSPACES,
	UpdateWorkspaceAction,
	UPDATE_WORKSPACE,
	Workspace,
	WorkspaceSchema,
} from './types';
import axios from '@utils/axios';
import { getApiEndPointUrl } from '../../../shared/utils/getApiEndpointUrl';
import { getWorkspacesEndPoint, workspaceEndPoint } from './const';
import { getApiError } from '../../../shared/utils/getApiError';
import { validate } from '../../../shared/utils/jsonschema';
import { fulfill, pend, reject } from '../../promise/actions';

function updateWorkspaceApi(workspaceId: string, updates: Partial<Workspace>) {
	return axios.patch(getApiEndPointUrl(workspaceEndPoint, workspaceId), updates);
}

function* getWorkspacesSaga(action: GetWorkspacesAction) {
	try {
		yield putEffect(pend(getWorkspaceAction));
		const res = yield axios.get(getApiEndPointUrl(getWorkspacesEndPoint), { params: action.payload });
		yield putEffect(setWorkspacesAction(validate(res.data, WorkspaceSchema)));
		yield putEffect(fulfill(getWorkspaceAction));
	} catch (e) {
		yield putEffect(reject(getWorkspaceAction, getApiError(e)));
	}
}

function* updateWorkspaceSaga(action: UpdateWorkspaceAction) {
	try {
		const { workspaceId, updates } = action.payload;
		yield putEffect(pend(updateWorkspaceAction));
		const res = yield updateWorkspaceApi(workspaceId, updates);
		yield putEffect(updateWorkspaceFulfilled(workspaceId, updates));
		yield putEffect(fulfill(updateWorkspaceAction, { workspaceId, updates, resData: res.data }));
	} catch (e) {
		yield putEffect(reject(updateWorkspaceAction, getApiError(e)));
	}
}

function* getWorkspacesWatcher() {
	yield takeLatest(GET_WORKSPACES, getWorkspacesSaga);
}

function* updateWorkspaceWatcher() {
	yield takeLatest(UPDATE_WORKSPACE, updateWorkspaceSaga);
}

export const workspaceDetailsSagas = [getWorkspacesWatcher, updateWorkspaceWatcher];
