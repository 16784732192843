import {
	ReadWorkspaceRoles,
	ReadWorkspaceRolesFulfilled,
	READ_WORKSPACE_ROLES,
	READ_WORKSPACE_ROLES_FULFILLED,
	Role,
} from './types';

export const readWorkspaceRoles = (workspaceId: string): ReadWorkspaceRoles => ({
	type: READ_WORKSPACE_ROLES,
	payload: workspaceId,
});

export const readWorkspaceRolesFulfilled = (roles: Role[]): ReadWorkspaceRolesFulfilled => ({
	type: READ_WORKSPACE_ROLES_FULFILLED,
	payload: roles,
});
