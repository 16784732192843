import axios from '@utils/axios';
import { takeLeading } from 'redux-saga/effects';
import { getApiEndPointUrl } from '../../shared/utils/getApiEndpointUrl';
import { getApiError } from '../../shared/utils/getApiError';
import { putEffect } from '../../shared/utils/putEffect';
import { pend, fulfill, reject } from '../promise/actions';
import {
	readQuota,
	readQuotaFulfilled,
	createQuota,
	createQuotaFulfilled,
    updateQuota,
    updateQuotaFulfilled,
    deleteQuota,
    deleteQuotaFulfilled,
	cloneQuota,
	cloneQuotaFulfilled,
    
} from './actions';
import { quotaEndPoint } from './const';
import {
    ReadQuota,
	CreateQuota,
    UpdateQuota,
    readQuotaInfo,
    READ_QUOTA,
  CREATE_QUOTA ,
  UPDATE_QUOTA ,
  DELETE_QUOTA, 
  DeleteQuota,
  CLONE_QUOTA,
  CloneQuota,
} from './types';


function readQuotaAPI(action:readQuotaInfo) {
    const { id, ...params } = action;
	return axios.get(getApiEndPointUrl(quotaEndPoint, id),{params});
}

function createQuotaAPI(action:any) {
	return axios.post(getApiEndPointUrl(quotaEndPoint),{...action});
}

function updateQuotaAPI(action: any) {
    const {id} = action
	return axios.patch(getApiEndPointUrl(quotaEndPoint, id),{...action});
}
function deleteQuotaAPI(action: any) {
	return axios.delete(getApiEndPointUrl(quotaEndPoint, action));
}
function cloneQuotaAPI(action: any) {
	const{existingQuotaId,newQuotaId} = action
	return axios.post(getApiEndPointUrl(quotaEndPoint,'clone', existingQuotaId,newQuotaId));
}
function* readQuotaSaga(action: ReadQuota) {
	try {
		yield putEffect(pend(readQuota));
		const res = yield readQuotaAPI(action.payload);
		yield putEffect(readQuotaFulfilled(res.data));
		yield putEffect(fulfill(readQuota));
	} catch (e) {
		yield putEffect(reject(readQuota, getApiError(e)));
	}
}
function* createQuotaSaga(action: CreateQuota) {
	try {
		yield putEffect(pend(createQuota));
		const res = yield createQuotaAPI(action.payload);
		yield putEffect(createQuotaFulfilled(res.data));
		yield putEffect(fulfill(createQuota));
	} catch (e) {
		yield putEffect(reject(createQuota, getApiError(e)));
	}
}

function* updateQuotaSaga(action: UpdateQuota) {
	try {
		yield putEffect(pend(updateQuota));
        const res =	yield updateQuotaAPI(action.payload);
		yield putEffect(updateQuotaFulfilled(res.data));
		yield putEffect(fulfill(updateQuota));
	} catch (e) {
		yield putEffect(reject(updateQuota, getApiError(e)));
	}
}
function* deleteQuotaSaga(action: DeleteQuota) {
	try {
		yield putEffect(pend(deleteQuota));
        yield deleteQuotaAPI(action.payload);
		yield putEffect(deleteQuotaFulfilled(action.payload));
		yield putEffect(fulfill(deleteQuota));
	} catch (e) {
		yield putEffect(reject(deleteQuota, getApiError(e)));
	}
}
function* cloneQuotaSaga(action: CloneQuota) {
	try {
		yield putEffect(pend(cloneQuota));
        const res =	yield cloneQuotaAPI(action.payload);
		yield putEffect(cloneQuotaFulfilled(res.data));
		yield putEffect(fulfill(cloneQuota));
	} catch (e) {
		yield putEffect(reject(cloneQuota, getApiError(e)));
	}
}


export const getQuotaWatchers = [
	function* () {
		yield takeLeading(READ_QUOTA, readQuotaSaga);
	},
	function* () {
		yield takeLeading(CREATE_QUOTA, createQuotaSaga);
	},
	function* () {
		yield takeLeading(UPDATE_QUOTA, updateQuotaSaga);
	},
    function* () {
		yield takeLeading(DELETE_QUOTA, deleteQuotaSaga);
	},
    function* () {
		yield takeLeading(CLONE_QUOTA, cloneQuotaSaga);
	},
];
