import { compose, lazy, mount, withView } from 'navi';
import React from 'react';
import { View } from 'react-navi';
import { routePaths } from './shared/consts/routePaths';

export const routes = compose(
	withView(() => {
		return (
			<div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
				<View />
			</div>
		);
	}),
	mount({
		[routePaths.home.root]: lazy(() => import('./pages/Home')),
		[routePaths.questionnaire.root]: lazy(() => import('./pages/Questionnaire')),
		[routePaths.auth.root]: lazy(() => import('./pages/Auth')),
		[routePaths.surveys.root]: lazy(() => import('./pages/Surveys')),
		[routePaths.workspaces.root]: lazy(() => import('./pages/Workspace')),
		[routePaths.creators.root]: lazy(() => import('./pages/Creators')),
	})
);
