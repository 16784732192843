import styled, { css } from 'styled-components';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

export const DrawerImage = styled.img`
	width: 80%;
`;

export const DrawerTitle = styled.div`
	${({ theme }) => `
    font-weight: ${theme.fonts.weight.bolder};
    font-size: ${theme.fonts.size.lead};
    line-height: ${theme.fonts.lineHeight.lead};
    color: ${theme.colors.primaryDark};
  `}
	padding-bottom: 1.11rem;
`;

export const DrawerSubTitle = styled.div`
	${({ theme }) => `
    font-size: ${theme.fonts.size.big};
    line-height: ${theme.fonts.lineHeight.bigger};
    color: ${theme.colors.primaryDark};
  `}
`;
export const StyledDrawerSpace = styled.div`
	flex-grow: 2;
`;
export const StyledDrawerText = styled.div`
	flex-grow: 3;
`;
const MixinText16 = css`
	font-family: ${({ theme }) => theme.fonts.family.primary};
	font-size: ${({ theme }) => theme.fonts.size.big};
	line-height: ${({ theme }) => theme.fonts.lineHeight.bigger};
	font-weight: ${({ theme }) => theme.fonts.weight.base};
	color: ${({ theme }) => theme.colors.primaryDark};
	margin: 0;
`;
export const StyledButton = styled.button<{ disabled?: boolean; margin?: string; onClick?: (e: Event) => void }>`
	${MixinText16}
	font-weight: ${({ theme }) => theme.fonts.weight.bold};
	background-color: ${({ theme, disabled }) => (disabled ? theme.colors.lightPurple : theme.colors.primaryButton)};
	border-radius: ${({ theme }) => theme.layouts.borderRadius.small};
	color: ${({ theme }) => theme.colors.surfaceLight};
	padding: ${({ theme }) => theme.layouts.padding.majorButton};
	border: none;
	cursor: pointer;
`;
export const StyledCancelButton = styled(StyledButton)`
	color: ${({ theme }) => theme.colors.primaryButton};
	cursor: pointer;
	border: none;
	background: none;
	margin: 0;
	padding: 0;
`;
export const StyledFooter = styled.footer`
	display: flex;
	justify-content: space-between;
	padding: 0 1.48rem;
`;
export const StyledRightOutlined = styled(RightOutlined)`
	padding-left: 0.65rem;
`;
export const StyledLeftOutlined = styled(LeftOutlined)`
	padding-right: 0.65rem;
`;
