import { reduceReducers } from '../../shared/utils/reduceReducers';
import { workspaceDetailReducer } from './detail/reducer';
import { roleReducer } from './role/reducer';
import { UnionAction, WorkspaceState } from './types';
import { userReducer } from './user/reducer';

const initialState: WorkspaceState = {
	workspaces: [],
	workspacesCount: 0,
	roles: [],
	users: [],
};

export const workspaceReducer = reduceReducers<WorkspaceState, UnionAction>(
	initialState,
	workspaceDetailReducer,
	roleReducer,
	userReducer
);
