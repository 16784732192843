import { stringify } from '@lytejs/query-string';
import { StorageKeys } from '../shared/enums/StorageKeys';
import { getValueForKeySessionStorage, getValueForKeyInLocalStorage } from '../shared/utils/storage';
import { ErrorWithStatus } from './error';

const isFlatObject = (object) =>
	!Object.values(object).find((v) => {
		return typeof v === 'object' && v !== null && !(v instanceof File);
	});
const hasFile = (object) => !!Object.values(object).find((v) => v instanceof File);
function getFormData(object) {
	const formData = new FormData();
	Object.keys(object).forEach((key) => {
		formData.append(key, object[key]);
	});
	return formData;
}

export const quickFetch = async <T>(
	url,
	{ headers = {}, method = 'get', redirect = 'follow', body = {}, params = {}, absoluteUrl = false } = {}
): Promise<T> => {
	const processedOptions: any = {
		headers,
		method,
		redirect,
		mode: 'cors',
		credentials: 'include',
	};

	if (Object.keys(body).length) {
		processedOptions.method = method === 'get' ? 'post' : method;
		if (isFlatObject(body) && hasFile(body)) {
			processedOptions.body = getFormData(body);
		} else {
			processedOptions.body = JSON.stringify(body);
			processedOptions.headers['Content-Type'] = processedOptions.headers['Content-Type'] || 'application/json';
		}
		// if (isFlatObject(body)) {
		//   if (hasFile(body)) {
		//     processedOptions.body = getFormData(body);
		//   } else {
		//     processedOptions.body = stringify(body);
		//     processedOptions.headers['Content-Type'] =
		//       processedOptions.headers['Content-Type'] || 'application/x-www-form-urlencoded';
		//   }
		// } else {
		//   processedOptions.body = JSON.stringify(body);
		//   processedOptions.headers['Content-Type'] = processedOptions.headers['Content-Type'] || 'application/json';
		// }
	}
	const token = getValueForKeySessionStorage(StorageKeys.Token) || getValueForKeyInLocalStorage(StorageKeys.Token);
	if (
		token
		//  && !environment.isSurveyPreview
	) {
		processedOptions.headers.Authorization = `Bearer ${token}`;
	}
	const q = stringify(params);
	const res = await fetch(`${url}${q ? `/?${q}` : ''}`, processedOptions);
	let records: string | null = null;
	try {
		records = await res.text();
		records = records.length ? JSON.parse(records) : '';
	} catch (e) {}
	if (res.ok) {
		return records as unknown as T;
	} else {
		throw new ErrorWithStatus(records, res.status);
	}
	// later use Error Object with stack when time
	// const error: any = {
	//   code: res?.status.toString()
	// }

	// // res.code = res?.status.toString();
	// try {
	//   error.message = await res.text();
	// } catch (e) {
	//   error.message = 'Some Error';
	// }
	// throw error;
};
