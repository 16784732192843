import { ReduxAction } from '../../../shared/types/ReduxAction';
import { ReduxReducer } from '../../../shared/types/ReduxReducer';
import { JSONSchema } from '../../../shared/utils/jsonschema';
import { SurveyResponses } from '../type';
export const READ_RESPONSES = 'READ_SURVEY_RESPONSES';
export const READ_RESPONSES_FULFILLED = 'READ_SURVEY_RESPONSES_FULFILLED';
export const PATCH_RESPONSE = 'PATCH_SURVEY_RESPONSE';
export const PATCH_RESPONSE_FULFILLED = 'PATCH_SURVEY_RESPONSE_FULFILLED';
export const PATCH_RESPONSES = 'PATCH_BULK_SURVEY_RESPOgitNSE';
export const PATCH_RESPONSES_FULFILLED = 'PATCH_BULK_SURVEY_RESPONSE_FULFILLED';
export const UPDATE_VIDEO_MARKERS = 'UPDATE_VIDEO_MARKERS';
type videoMarkers = {
	description: string;
	id: string;
	text: string;
	time: number;
	type: string;
	username?: string;
	profilePicture?: string;
}[];
export type SurveyResponse = {
	endTime: string;
	id: string;
	nicheId: string;
	respondent: string;
	from: string;
	responses: any;
	_id: string;
	approved: boolean;
};
export type ResponseEntities = {
	metaData: { total: number };
	entities: SurveyResponse[];
};
export const SurveyResponseSchema: JSONSchema = {
	type: 'object',
	properties: {
		metaData: { type: 'object', properties: { total: { type: 'number' } } },
		entities: { type: 'array', items: { type: 'object' } },
	},
};
export type UpdateVideoMarkersPayload = { responseId: string; questionnaireId: string; videoMarkers: videoMarkers };
export type PatchResponsePayload = {
	questionnaireId: string;
	responseId: string;
	approved: boolean;
	successMessage: String;
	readResonsePayload: Object;
	isApproveAll?: boolean;
	data?: any;
};
export type PatchResponsesPayload = { questionnaireId: string; cohortId: string };
export type ReadResponsesPayload = {
	id: string;
	offset?: number;
	limit?: number;
	cohorts?: string[];
	columns?: any;
	to?: string;
	from?: string;
};
export type ReadResponses = ReduxAction<typeof READ_RESPONSES, ReadResponsesPayload>;
export type ReadResponsesFulfilled = ReduxAction<typeof READ_RESPONSES_FULFILLED, ResponseEntities>;
export type UpdateVideoMarkers = ReduxAction<typeof UPDATE_VIDEO_MARKERS, UpdateVideoMarkersPayload>;
export type PatchResponse = ReduxAction<typeof PATCH_RESPONSE, PatchResponsePayload>;
export type PatchResponseFulfilled = ReduxAction<typeof PATCH_RESPONSE_FULFILLED, PatchResponsePayload>;
export type PatchResponses = ReduxAction<typeof PATCH_RESPONSES, PatchResponsesPayload>;
export type PatchResponsesFulfilled = ReduxAction<typeof PATCH_RESPONSES_FULFILLED, null>;

export type SurveyResponsesAction =
	| ReadResponses
	| ReadResponsesFulfilled
	| PatchResponse
	| PatchResponseFulfilled
	| PatchResponses
	| PatchResponsesFulfilled;

export type EntitiesReducer = ReduxReducer<SurveyResponses, SurveyResponsesAction>;
