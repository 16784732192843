import { ReduxAction } from '../../shared/types/ReduxAction';
import { ReduxReducer } from '../../shared/types/ReduxReducer';
import { RequestStatus } from '../../shared/enums/RequestStatus';

export type AddUserError = {
	status: RequestStatus.Rejected;
	error: string;
};

export type CreateUsersState = {
	addUserStatus: RequestStatus;
	addUserError: AddUserError | null;
};

export type UserData = { firstName: string; lastName; email: string; role: string };

export const CREATE_USERS = 'CREATE_USERS';
export type CreateUsersPayload = { users: UserData[]; workspaceId };
export type CreateUsersAction = ReduxAction<typeof CREATE_USERS, CreateUsersPayload>;

export const CREATE_USERS_PENDING = 'CREATE_USERS_PENDING';
export type CreateUsersPendingAction = ReduxAction<typeof CREATE_USERS_PENDING, RequestStatus.Pending>;

export const CREATE_USERS_FULFILLED = 'CREATE_USERS_FULFILLED';
export type CreateUsersFulfilledAction = ReduxAction<typeof CREATE_USERS_FULFILLED, RequestStatus.Fulfilled>;

export const CREATE_USERS_REJECTED = 'CREATE_USERS_REJECTED';
export type CreateUsersRejected = ReduxAction<typeof CREATE_USERS_REJECTED, AddUserError>;

export const RESET_CREATE_USERS_STATE = 'RESET_ADD_USERS_STATE';
export type ResetUsersAction = ReduxAction<typeof RESET_CREATE_USERS_STATE, RequestStatus.Idle>;

export type AddUsersAction =
	| CreateUsersAction
	| CreateUsersPendingAction
	| CreateUsersFulfilledAction
	| CreateUsersRejected
	| ResetUsersAction;

export type CreateUsersReducer = ReduxReducer<CreateUsersState, AddUsersAction>;
