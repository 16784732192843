import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { combineWatchers } from '../shared/utils/conbineWatchers';
import { userReducer } from './auth/reducer';
import { userSagas } from './auth/sagas';
import { UserState } from './auth/types';
import { redirectReducer } from './redirect/reducer';
import { RedirectState } from './redirect/types';
import { InternalDashboardState } from './internal-dashboard/surveys/types';
import { internalDashboardSharedReducer } from './internal-dashboard/surveys/reducer';
import { internalDashboardSagas } from './internal-dashboard/shared/sagas';
import { createUsersReducer } from './createUsers/reducer';
import { CreateUsersState } from './createUsers/types';
import { createUserSagas } from './createUsers/saga';
import { promiseReducer } from './promise/reducer';
import { customVariableReducer } from './CustomVariable/reducer';
import { getCustomVariablesWatchers } from './CustomVariable/saga';
import { PromiseState } from './promise/types';
import { VariablesState } from './CustomVariable/types';
import { RedirectURLsState } from './redirectURLs/types';
import { redirectURLsReducer } from './redirectURLs/reducer';
import { getRedirectURLsWatchers } from './redirectURLs/saga';
import { SurveyResponses } from './SurveyResponse/type';
import { surveyResponsesSagas } from './SurveyResponse/sagas';
import { ResponsesReducer } from './SurveyResponse/reducer';
import { WorkspaceState } from './workspace/types';
import { workspaceReducer } from './workspace/reducer';
import { workspaceSagas } from './workspace/sagas';
import { getQuotaWatchers } from './SetQuota/saga';
import { QuotaInfoState } from './SetQuota/types';
import { quotasReducer } from './SetQuota/reducer';

const watchers = combineWatchers(
	userSagas,
	internalDashboardSagas,
	workspaceSagas,
	createUserSagas,
	getCustomVariablesWatchers,
	surveyResponsesSagas,
	getRedirectURLsWatchers,
	getQuotaWatchers
);

export function* rootSaga() {
	yield all(watchers);
}
const sagaMiddleware = createSagaMiddleware();

export type StoreState = {
	user: UserState;
	redirect: RedirectState;
	internalDashboard: InternalDashboardState;
	workspace: WorkspaceState;
	createUsers: CreateUsersState;
	customVariables: VariablesState;
	redirectURLs: RedirectURLsState;
	quota:QuotaInfoState;
	promise: PromiseState;
	surveyResponses: SurveyResponses;
};

const surveyDesignerReducers = {
	user: userReducer,
	redirect: redirectReducer,
	internalDashboard: internalDashboardSharedReducer,
	workspace: workspaceReducer,
	createUsers: createUsersReducer,
	promise: promiseReducer,
	customVariables: customVariableReducer,
	redirectURLs: redirectURLsReducer,
	quota:quotasReducer,
	surveyResponses: ResponsesReducer,
};

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
	combineReducers(surveyDesignerReducers),
	composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);
