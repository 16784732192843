import { ReduxAction } from '../../shared/types/ReduxAction';
import { ReduxReducer } from '../../shared/types/ReduxReducer';
import { JSONSchema } from '../../shared/utils/jsonschema';
export const READ_REDIRECT_URLS = 'READ_REDIRECT_URLS';
export const READ_REDIRECT_URLS_FULFILLED = 'READ_REDIRECT_URLS_FULFILLED';
export const UPDATE_REDIRECT_URLS = 'UPDATE_REDIRECT_URLS';
export const UPDATE_REDIRECT_URLS_FULFILLED = 'UPDATE_REDIRECT_URLS_FULFILLED';

export type RedirectURL = {
	url: string;
	panel: string;
};
export type RedirectURLsState = {
	entities: RedirectURL[];
};
export const RedirectURLSchema: JSONSchema = {
	type: 'object',
	properties: {
		panel: { type: 'string' },
		url: { type: 'string' },
	},
};
export const RedirectURLsSchema: JSONSchema = {
	type: 'array',
	items: RedirectURLSchema,
};
export type UpdateRedirectURLsPayload = {
	redirectURLs: RedirectURL[];
	surveyId: string;
};
export type ReadRedirectURLs = ReduxAction<typeof READ_REDIRECT_URLS, string>;
export type ReadRedirectURLsFulfilled = ReduxAction<typeof READ_REDIRECT_URLS_FULFILLED, RedirectURL[]>;
export type UpdateRedirectURLs = ReduxAction<typeof UPDATE_REDIRECT_URLS, UpdateRedirectURLsPayload>;
export type UpdateRedirectURLsFulfilled = ReduxAction<typeof UPDATE_REDIRECT_URLS_FULFILLED, UpdateRedirectURLsPayload>;

export type RedirectURLsAction =
	| ReadRedirectURLs
	| ReadRedirectURLsFulfilled
	| UpdateRedirectURLs
	| UpdateRedirectURLsFulfilled;

export type RedirectURLsReducer = ReduxReducer<RedirectURLsState, RedirectURLsAction>;
