import axios from '@utils/axios';
import { takeLatest } from 'redux-saga/effects';
import { putEffect } from '../../../shared/utils/putEffect';
import { ReadSurveysAction, READ_SURVEYS, UPDATE_SURVEY_INSTRUCTION, UpdateSurveyInstruction } from './types';
import { getSurveysEndPoint, surveyEndPoint } from './const';
import {
	readSurveysAction,
	readSurveysFulfilledAction,
	updateSurveyInstruction,
	updateSurveyInstructionFulfilled,
} from './actions';
import { prepareData } from './utils';
import { getApiError } from '../../../shared/utils/getApiError';
import { getApiEndPointUrl } from '../../../shared/utils/getApiEndpointUrl';
import { pend, fulfill, reject } from '../../promise/actions';

function* readSurveysSaga(action: ReadSurveysAction) {
	try {
		yield putEffect(pend(readSurveysAction));
		const res = yield axios.get(getApiEndPointUrl(getSurveysEndPoint, 'info'), { params: action.payload });
		yield putEffect(
			readSurveysFulfilledAction({ items: prepareData(res.data.items), count: res.data.count as number })
		);
		yield putEffect(fulfill(readSurveysAction));
		yield;
	} catch (e) {
		yield putEffect(reject(readSurveysAction, getApiError(e)));
	}
}

function* updateInstructionSaga(action: UpdateSurveyInstruction) {
	try {
		yield putEffect(pend(updateSurveyInstruction));
		const { instruction, surveyId } = action.payload;
		const res = yield axios.patch(getApiEndPointUrl(surveyEndPoint, surveyId), { instruction });
		yield putEffect(updateSurveyInstructionFulfilled({ instruction: res.data.instruction, surveyId: res.data.id }));
		yield putEffect(fulfill(updateSurveyInstruction));
		yield;
	} catch (e) {
		yield putEffect(reject(updateSurveyInstruction, getApiError(e)));
	}
}

function* readSurveysWatcher() {
	yield takeLatest(READ_SURVEYS, readSurveysSaga);
}

function* updateInstructionWatcher() {
	yield takeLatest(UPDATE_SURVEY_INSTRUCTION, updateInstructionSaga);
}

export const surveysSagas = [readSurveysWatcher, updateInstructionWatcher];
