import { RedirectURLsReducer, RedirectURLsAction, READ_REDIRECT_URLS_FULFILLED } from './types';
const initialState = {
	entities: [],
};
export const redirectURLsReducer: RedirectURLsReducer = (state = initialState, action: RedirectURLsAction) => {
	switch (action.type) {
		case READ_REDIRECT_URLS_FULFILLED:
			return {
				...state,
				entities: action.payload,
			};
		default:
			return state;
	}
};
