import { ModalProps, Image } from 'pyxis-ui-kit';
import loader from '../../../assets/icons/Loader.gif';
import { theme } from '../../consts/theme';
import { StyledModal } from './styled';
import React, { FC } from 'react';
export type PyxisLoaderProps = ModalProps & {};

const PyxisLoader: FC<PyxisLoaderProps> = ({ ...props }) => {
	return (
		<StyledModal
			{...props}
			centered
			bodyStyle={{ padding: 0 }}
			zIndex={theme.layouts.zIndex.modal}
			footer={null}
			closable={false}
		>
			<Image width={50} src={loader} preview={false} />
		</StyledModal>
	);
};

export default PyxisLoader;
