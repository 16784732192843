import {
	ReadRedirectURLs,
	READ_REDIRECT_URLS,
	ReadRedirectURLsFulfilled,
	READ_REDIRECT_URLS_FULFILLED,
	RedirectURL,
	UPDATE_REDIRECT_URLS,
	UPDATE_REDIRECT_URLS_FULFILLED,
	UpdateRedirectURLsPayload,
	UpdateRedirectURLs,
	UpdateRedirectURLsFulfilled,
} from './types';
export const readRedirectURLs = (payload: string): ReadRedirectURLs => ({
	type: READ_REDIRECT_URLS,
	payload,
});

export const readRedirectURLsFulfilled = (payload: RedirectURL[]): ReadRedirectURLsFulfilled => ({
	type: READ_REDIRECT_URLS_FULFILLED,
	payload: payload,
});

export const updateRedirectURLs = (payload: UpdateRedirectURLsPayload): UpdateRedirectURLs => ({
	type: UPDATE_REDIRECT_URLS,
	payload,
});

export const updateRedirectURLsFulfilled = (payload: UpdateRedirectURLsPayload): UpdateRedirectURLsFulfilled => ({
	type: UPDATE_REDIRECT_URLS_FULFILLED,
	payload: payload,
});
