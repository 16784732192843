import {
	CreateWorkspaceUsers,
	CREATE_WORKSPACE_USERS,
	User,
	CreateWorkspaceUsersFulfilled,
	CREATE_WORKSPACE_USERS_FULFILLED,
} from './types';

export const createWorkspaceUsers = (user: User[]): CreateWorkspaceUsers => ({
	type: CREATE_WORKSPACE_USERS,
	payload: user,
});

export const createWorkspaceUsersFulfilled = (user: User[]): CreateWorkspaceUsersFulfilled => ({
	type: CREATE_WORKSPACE_USERS_FULFILLED,
	payload: user,
});
