import React, { lazy, Suspense } from 'react';
import { Router, useCurrentRoute, useNavigation, View } from 'react-navi';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import './App.scss';
import { ReduxErrorHandler, ReactQueryErrorHandler } from './components/ErrorHandler/ErrorHandler';
import { Loader, ReactQueryLoader } from './components/Loader/Loader';
import { routes } from './routes';
import { usePutProfile, useReadProfile } from './services/profile';
import DrawerProvider from './shared/components/ConfirmDrawer/DrawerProvider';
import RedirectWatcher from './shared/components/RedirectWatcher';
import { theme } from './shared/consts/theme';
import { useDebouncedEffect } from './shared/utils/useDebouncedEffect';
import { useMount } from './shared/utils/useMount';
import { StoreState } from './store';
import { readUserRoles } from './store/auth/actions';
import { Role } from './store/auth/types';
import { useReduxQuery } from './utils/reduxQuery/useReduxMutation';
import { UrlQueryProvider } from './utils/urlQuery';

const App = () => {
	const GlobalStyles = lazy(() => import('./styles/GlobalStyles'));
	const userRoles = useSelector<StoreState, Role[]>((s) => s.user?.roles || []);
	const hasMounted = useMount();
	const { isSuccess, isError } = useReadProfile();
	const { isSettled: isRolesSettled } = useReduxQuery([], readUserRoles, {
		hideGlobalError: true,
		enabled: hasMounted,
	});

	return (
		<>
			<ThemeProvider theme={theme}>
				<DrawerProvider>
					<div className="app">
						<Loader />
						<ReduxErrorHandler />
						<ReactQueryLoader />
						<ReactQueryErrorHandler />
						<Router
							routes={routes}
							context={{
								isSettled: (isSuccess || isError) && isRolesSettled,
								user: {
									isAuthenticated: isSuccess,
									userRoles,
								},
							}}
						>
							<Suspense fallback={<></>}>
								<GlobalStyles />
								<RedirectWatcher />
								<InternalApp>
									<View />
								</InternalApp>
							</Suspense>
						</Router>
					</div>
				</DrawerProvider>
			</ThemeProvider>
			<ReactQueryDevtools initialIsOpen={false} /></>
	);
};
const InternalApp = ({ children }) => {
	const { navigate } = useNavigation();
	const {
		lastChunk: { request },
	} = useCurrentRoute();
	const workspaceId = request?.params.workspaceId;
	const { data: profile } = useReadProfile();
	const { mutate } = usePutProfile();
	// Debounce for 5 second to make sure that yeah workspace is correct and no redirects will happen
	useDebouncedEffect(
		() => {
			if (workspaceId === profile?.currentWorkspaceId || !profile?.id || !workspaceId) return;
			mutate({
				id: profile?.id,
				currentWorkspaceId: workspaceId || null,
			});
		},
		[workspaceId, profile?.currentWorkspaceId, profile?.id, mutate],
		5000
	);
	return (
		<UrlQueryProvider
			push={navigate}
			useRoute={useCurrentRoute}
			getQuery={(object) => {
				const {
					lastChunk: { request },
				} = object;
				return request?.query;
			}}
		>
			{children}
		</UrlQueryProvider>
	);
};
export default App;
