import axios from '@utils/axios';
import { takeLeading } from 'redux-saga/effects';
import { getApiEndPointUrl } from '../../shared/utils/getApiEndpointUrl';
import { getApiError } from '../../shared/utils/getApiError';
import { validate } from '../../shared/utils/jsonschema';
import { putEffect } from '../../shared/utils/putEffect';
import { pend, fulfill, reject } from '../promise/actions';
import {
	readRedirectURLs,
	readRedirectURLsFulfilled,
	updateRedirectURLsFulfilled,
	updateRedirectURLs,
} from './actions';
import { surveyEndPoint, RedirectURLsEndPoint } from './const';
import {
	READ_REDIRECT_URLS,
	ReadRedirectURLs,
	RedirectURLsSchema,
	UpdateRedirectURLsPayload,
	UpdateRedirectURLs,
	UPDATE_REDIRECT_URLS,
} from './types';
function readCustomVariablesAPI(id: string) {
	return axios.get(getApiEndPointUrl(surveyEndPoint, id, RedirectURLsEndPoint));
}

function updateCustomVariableAPI({ redirectURLs, surveyId }: UpdateRedirectURLsPayload) {
	return axios.put(getApiEndPointUrl(surveyEndPoint, surveyId, RedirectURLsEndPoint), { redirectURLs });
}

function* updateCustomVariableSaga(action: UpdateRedirectURLs) {
	try {
		yield putEffect(pend(updateRedirectURLs));
		yield updateCustomVariableAPI(action.payload);
		yield putEffect(updateRedirectURLsFulfilled(action.payload));
		yield putEffect(fulfill(updateRedirectURLs));
	} catch (e) {
		yield putEffect(reject(updateRedirectURLs, getApiError(e)));
	}
}

function* readCustomVariablesSaga(action: ReadRedirectURLs) {
	try {
		yield putEffect(pend(readRedirectURLs));
		const res = yield readCustomVariablesAPI(action.payload);

		yield putEffect(readRedirectURLsFulfilled(validate(res.data, RedirectURLsSchema)));
		yield putEffect(fulfill(readRedirectURLs));
	} catch (e) {
		yield putEffect(reject(readRedirectURLs, getApiError(e)));
	}
}

export const getRedirectURLsWatchers = [
	function* () {
		yield takeLeading(READ_REDIRECT_URLS, readCustomVariablesSaga);
	},
	function* () {
		yield takeLeading(UPDATE_REDIRECT_URLS, updateCustomVariableSaga);
	},
];
