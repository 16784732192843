import { reduceReducers } from '../../shared/utils/reduceReducers';
import { surveyResponseReducer } from './entities/reducer';
import { SurveyResponses } from './type';
import { responsesVisibiltyReducer } from './visibility/reducer';

const initialState: SurveyResponses = {
	metaData: { total: 0 },
	entities: [],
	visibility: true,
};

export const ResponsesReducer = reduceReducers(initialState, surveyResponseReducer, responsesVisibiltyReducer);
