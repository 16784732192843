import { READ_WORKSPACE_ROLES_FULFILLED, RoleReducer } from './types';

export const roleReducer: RoleReducer = (state, action) => {
	switch (action.type) {
		case READ_WORKSPACE_ROLES_FULFILLED:
			return { ...state, roles: action.payload };
		default:
			return state;
	}
};
