import {
	READ_SURVEYS,
	ReadSurveysAction,
	READ_SURVEYS_FULFILLED,
	ReadSurveysFulfilledAction,
	ReadSurveysFulfilledPayload,
	ReadSurveysPayload,
	UpdateSurveyInstructionPayload,
	UpdateSurveyInstruction,
	UPDATE_SURVEY_INSTRUCTION,
	UpdateSurveyInstructionFulfilled,
	UPDATE_SURVEY_INSTRUCTION_FULFILLED,
} from './types';

export const readSurveysAction: (payload: ReadSurveysPayload) => ReadSurveysAction = (payload) => ({
	type: READ_SURVEYS,
	payload,
});

export const readSurveysFulfilledAction: (payload: ReadSurveysFulfilledPayload) => ReadSurveysFulfilledAction = (
	payload
) => ({
	type: READ_SURVEYS_FULFILLED,
	payload,
});

export const updateSurveyInstruction: (payload: UpdateSurveyInstructionPayload) => UpdateSurveyInstruction = (
	payload
) => ({
	type: UPDATE_SURVEY_INSTRUCTION,
	payload,
});

export const updateSurveyInstructionFulfilled: (
	payload: UpdateSurveyInstructionPayload
) => UpdateSurveyInstructionFulfilled = (payload) => ({
	type: UPDATE_SURVEY_INSTRUCTION_FULFILLED,
	payload,
});
