import { takeLatest } from '@redux-saga/core/effects';
import { putEffect } from '../../../shared/utils/putEffect';
import { readWorkspaceRoles, readWorkspaceRolesFulfilled } from './action';
import axios from '@utils/axios';
import { getApiEndPointUrl } from '../../../shared/utils/getApiEndpointUrl';
import { getApiError } from '../../../shared/utils/getApiError';
import { fulfill, pend, reject } from '../../promise/actions';
import { ReadWorkspaceRoles, READ_WORKSPACE_ROLES } from './types';

const getWorkspaceRolesEndPoint = 'roles';

function* getWorkspaceRolesSaga({ payload }: ReadWorkspaceRoles) {
	try {
		yield putEffect(pend(readWorkspaceRoles));
		const res = yield axios.get(getApiEndPointUrl(getWorkspaceRolesEndPoint), { params: { workspaceId: payload } });
		yield putEffect(readWorkspaceRolesFulfilled(res.data));
		yield putEffect(fulfill(readWorkspaceRoles));
	} catch (e) {
		yield putEffect(reject(readWorkspaceRoles, getApiError(e)));
	}
}

export const rolesSagas = [
	function* () {
		yield takeLatest(READ_WORKSPACE_ROLES, getWorkspaceRolesSaga);
	},
];
