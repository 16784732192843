import { CREATE_WORKSPACE_USERS_FULFILLED, UserReducer } from './types';

export const userReducer: UserReducer = (state, action) => {
	switch (action.type) {
		case CREATE_WORKSPACE_USERS_FULFILLED:
			return { ...state, users: action.payload };
		default:
			return state;
	}
};
