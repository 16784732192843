export enum SurveyStatus {
	All = 'all',
	Approved = 'approved',
	Scheduled = 'scheduled',
	Draft = 'draft',
	Completed = 'completed',
	Archived = 'archived',
	Rejected = 'rejected',
	Unfinished = 'unfinished',
	InReview = 'inReview',
	Active = 'active',
	InCancellation = 'inCancellation',
}
