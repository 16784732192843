import debounce from 'lodash.debounce';
import React, { useContext, useCallback, useMemo } from 'react';
// dont use this func on array params as it is not suitable. ideal array conversion if done will not be picked by navi
import { stringify, parse } from '@lytejs/query-string';
const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE = 1;
/** this function makes sure that other query params stay intact, & only `page` and `size` are changed  */
const getRouteUrl = (querySearch, prefix) => {
	const query = parse(typeof window === 'undefined' ? '' : window.location.search);
	Object.keys(querySearch).forEach((key) => {
		if (key === 'offset') query[`${prefix}-page`] = Number(querySearch.offset) / Number(querySearch.limit) + 1;
		else {
			query[`${prefix}-${key}`] = querySearch[key];
		}
	});
	return `?${stringify(query)}`;
};
type Query = { [key: string]: any };

export const filterUrlQueryByPrefix = (query, prefix, initialQuery: Query): Query => {
	const result = Object.keys(query).reduce(
		(acc, key) => {
			if (key.startsWith(`${prefix}-`)) acc[key.slice(prefix.length + 1)] = query[key];
			return acc;
		},
		{
			page: DEFAULT_PAGE,
			limit: DEFAULT_PAGE_SIZE,
			offset: 0,
			...initialQuery,
		}
	);
	const { page, ...q }: Query = result;
	q.offset = (Number(page) - 1) * Number(q.limit);
	return q;
};
export const UrlQueryContext = React.createContext({
	push: (t: string) => {},
	getQuery: (obj: any): any => ({}),
	useRoute: (): any => {},
});
export const UrlQueryProvider = ({ children, push, getQuery, useRoute }) => {
	const contextValue = useMemo(
		() => ({
			push,
			getQuery,
			useRoute,
		}),
		[getQuery, push, useRoute]
	);
	return <UrlQueryContext.Provider value={contextValue}>{children}</UrlQueryContext.Provider>;
};

export const useUrlQuery = ({
	prefix = '', // to support multiple url based pagination in single page
	defaultQuery = {},
}: {
	prefix: string;
	defaultQuery?: Query;
}) => {
	const { push, useRoute, getQuery } = useContext(UrlQueryContext);
	const route = useRoute();
	const query = useMemo(() => getQuery(route), [getQuery, route]);
	const filteredQuery: Query = useMemo(
		() =>
			filterUrlQueryByPrefix(parse(typeof window === 'undefined' ? '' : window.location.search), prefix, defaultQuery),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[prefix, defaultQuery, query]
	);
	const nextPageLink = useMemo(
		() => getRouteUrl({ ...filteredQuery, offset: Number(filteredQuery.offset) + Number(filteredQuery.limit) }, prefix),
		[prefix, filteredQuery]
	);

	const prevPageLink = useMemo(
		() => getRouteUrl({ ...filteredQuery, offset: Number(filteredQuery.offset) - Number(filteredQuery.limit) }, prefix),
		[prefix, filteredQuery]
	);
	const updateQuery = useCallback(
		(newQuery) => {
			push(
				getRouteUrl(
					{
						...filteredQuery,
						offset: 0,
						...newQuery,
					},
					prefix
				)
			);
		},
		[filteredQuery, prefix, push]
	);
	const queryUrl = useMemo(() => getRouteUrl(filteredQuery, prefix), [filteredQuery, prefix]);
	return {
		query: filteredQuery,
		nextPageLink,
		prevPageLink,
		updateQuery,
		updateQueryWithDebounce: debounce(updateQuery, 500),
		queryUrl,
	};
};
