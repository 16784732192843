import {
	CREATE_USERS_FULFILLED,
	CREATE_USERS_PENDING,
	CREATE_USERS_REJECTED,
	RESET_CREATE_USERS_STATE,
	CreateUsersReducer,
	CreateUsersState,
} from './types';
import { RequestStatus } from '../../shared/enums/RequestStatus';

const initialState: CreateUsersState = {
	addUserError: null,
	addUserStatus: RequestStatus.Idle,
};

export const createUsersReducer: CreateUsersReducer = (state = initialState, action) => {
	switch (action.type) {
		case CREATE_USERS_PENDING:
		case CREATE_USERS_FULFILLED:
			return {
				...state,
				addUserRequestStatus: action.payload,
			};

		case CREATE_USERS_REJECTED:
			return {
				...state,
				addUserError: action.payload,
				addUserStatus: action.payload.status,
			};
		case RESET_CREATE_USERS_STATE:
			return {
				...state,
				addUserStatus: action.payload,
				addUserError: null,
			};
		default:
			return state;
	}
};
