import { equals } from 'ramda';

interface JSONArraySchema {
	type: 'array';
	items?: JSONSchema;
	properties?: never;
	value?: never;
	anyOf?: never;
}
interface JSONObjectSchema {
	required?: string[];
	type: 'object';
	properties?: {
		[k: string]: JSONSchema;
	};
	items?: never;
	additionalProperties?: never;
	value?: any;
	anyOf?: never;
}
interface JSONNormalSchema {
	required?: never;
	type?: 'string' | 'number' | 'boolean' | 'null' | 'undefined';
	properties?: never;
	items?: never;
	additionalProperties?: never;
	anyOf?: never;
	value?: any;
}
interface JSONAnyOfSchema {
	value?: never;
	required?: never;
	type?: never;
	anyOf: JSONSchema[];
	properties?: never;
	items?: never;
	additionalProperties?: never;
}
export type JSONSchema = JSONArraySchema | JSONObjectSchema | JSONNormalSchema | JSONAnyOfSchema;
export const validate = (instance: any, schema: JSONSchema, isRecursiveCall?: boolean) => {
	let isValid: boolean = true;
	if (schema.anyOf) {
		isValid = !!schema.anyOf.filter((scheme) => validate(instance, scheme, true)).length;
	}
	if (schema.type && isValid) isValid = typeof instance === schema.type;
	if (schema.value && isValid) isValid = equals(instance, schema.value);
	if (schema.type === 'array') {
		isValid = Array.isArray(instance);
	}
	const schemaProperties = schema.properties;
	if (schemaProperties && isValid) {
		isValid =
			isValid &&
			instance !== null &&
			!Object.keys(schemaProperties).filter((property) => {
				return !validate(instance[property], schemaProperties[property], true);
			}).length;
	}
	const schemaItems = schema.items;

	if (schemaItems && schema.type === 'array' && isValid) {
		isValid =
			isValid &&
			!instance.filter((item: any) => {
				return !validate(item, schemaItems, true);
			}).length;
	}
	if (isRecursiveCall) return isValid;
	if (isValid) return instance;
	throw new Error(`Something went wrong`);
};
