import { ReduxAction } from '../../../shared/types/ReduxAction';
import { ReduxReducer } from '../../../shared/types/ReduxReducer';
import { JSONSchema } from '../../../shared/utils/jsonschema';
import { WorkspaceState } from '../types';

export type Brand = {
	id: string;
	name: string;
	macroTargetingGroupId: string;
};

export const WorkspaceSchema: JSONSchema = {
	type: 'object',
};

export type User = {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
};

export type WorkspaceUser = {
	user: User;
};

export type CreditsRange = {
	lowRange: number;
	midRange: number;
	highRange: number;
};
export type ReadWorkspacesPayload = {
	limit?: number;

	offset?: number;
	name?: string;
};
export type Workspace = {
	id: string;
	name: string;
	brands: Brand[];
	workspaceUsers: WorkspaceUser[];
	totalCredits: number;
	applicationCredits: CreditsRange;
	prototypeCredits: CreditsRange;
	websiteCredits: CreditsRange;
};

export const SET_WORKSPACES = 'SET_WORKSPACE';
export const UPDATE_WORKSPACE = 'UPDATE_WORKSPACE';
export const UPDATE_WORKSPACE_FULFILLED = 'UPDATE_WORKSPACE_FULFILLED';

export type SetWorkspaceActionPayload = { count: number; items: Workspace[] };
export type UpdateWorkspaceActionPayload = { workspaceId: string; updates: Partial<Workspace> };
export type UpdateWorkspaceFulfilledPayload = { workspaceId: string; updates: Partial<Workspace> };

export type SetWorkspaceAction = ReduxAction<typeof SET_WORKSPACES, SetWorkspaceActionPayload>;
export type UpdateWorkspaceAction = ReduxAction<typeof UPDATE_WORKSPACE, UpdateWorkspaceActionPayload>;
export type UpdateWorkspaceFulfilled = ReduxAction<typeof UPDATE_WORKSPACE_FULFILLED, UpdateWorkspaceFulfilledPayload>;

export const GET_WORKSPACES = 'GET_WORKSPACES';
export type GetWorkspacesAction = ReduxAction<typeof GET_WORKSPACES, ReadWorkspacesPayload>;

export type WorkspaceAction =
	| SetWorkspaceAction
	| GetWorkspacesAction
	| UpdateWorkspaceAction
	| UpdateWorkspaceFulfilled;

export type WorkspaceReducer = ReduxReducer<WorkspaceState, WorkspaceAction>;
