import { ReduxAction } from '../../shared/types/ReduxAction';
import { ReduxReducer } from '../../shared/types/ReduxReducer';
import { JSONSchema } from '../../shared/utils/jsonschema';
export const READ_CUSTOM_VARIABLES = 'READ_CUSTOM_VARIABLES';
export const READ_CUSTOM_VARIABLES_FULFILLED = 'READ_CUSTOM_VARIABLES_FULFILLED';
export const UPDATE_CUSTOM_VARIABLES = 'UPDATE_CUSTOM_VARIABLES';
export const UPDATE_CUSTOM_VARIABLES_FULFILLED = 'UPDATE_CUSTOM_VARIABLES_FULFILLED';

export type Variable = {
	label: string;
	apiIdentifier: string;
	description: string;
	panel: string;
};
export type VariablesState = {
	customeVariables: Variable[];
};
export const CustomVariableSchema: JSONSchema = {
	type: 'object',
	properties: {
		label: { type: 'string' },
		apiIdentifier: { type: 'string' },
	},
};
export const CustomVariablesSchema: JSONSchema = {
	type: 'array',
	items: CustomVariableSchema,
};
export type UpdateCustomVariablePayload = {
	variables: {
		description: string;
		label: string;
		apiIdentifier: string;
	}[];
	surveyId: string;
};
export type ReadCustomVariables = ReduxAction<typeof READ_CUSTOM_VARIABLES, string>;
export type ReadCustomVariablesFulfilled = ReduxAction<typeof READ_CUSTOM_VARIABLES_FULFILLED, Variable[]>;
export type UpdateCustomVariables = ReduxAction<typeof UPDATE_CUSTOM_VARIABLES, UpdateCustomVariablePayload>;
export type UpdateCustomVariableFulfilled = ReduxAction<
	typeof UPDATE_CUSTOM_VARIABLES_FULFILLED,
	UpdateCustomVariablePayload
>;

export type CustomVariablesAction =
	| ReadCustomVariables
	| ReadCustomVariablesFulfilled
	| UpdateCustomVariables
	| UpdateCustomVariableFulfilled;

export type CustomVariablesReducer = ReduxReducer<VariablesState, CustomVariablesAction>;
