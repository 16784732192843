import { ReduxAction } from '../../../shared/types/ReduxAction';
import { ReduxReducer } from '../../../shared/types/ReduxReducer';
import { InternalDashboardState } from '../surveys/types';
import { SurveyStatus } from '../../../shared/enums/SurveyStatus';
import { Macro, MacroRes } from '../../../shared/types/Macro';
import { Niche } from '../../../shared/types/Niche';

export type InternalDashboardQuestionnaireRes = {
	id: string;
	name: string;
	allowMultipleResponse: boolean;
	description: string;
	pixelId: string | null;
	brand: {
		id: string;
		name: string;
		workspace: {
			id: string;
			name: string;
		};
	};
	macroTargetingGroup: MacroRes;
	creator: {
		id: string;
		firstName: string;
		lastName: string;
		email: string;
		locusId: string;
		companyName: string;
		phoneNumber: string | null;
		profilePicture: string | null;
	};
	createdAt: string | null;
	startTime: string | null;
	endTime: string | null;
	publishTime: null | string;
	cancelTime: null | string;
	paymentStatus: null | string;
	status: SurveyStatus;
	surveys: Array<{
		id: string;
		orderPosition: number;
		questionnaireId: string;
		credits: number;
		instruction: Array<{
			id: string;
			instruction: string[];
		}>;
		totalRespondents: number;
		research: {
			id: string;
			researchModule: string;
			researchType: string;
			subResearch: string[];
		};
		moderatedTesting: {
			name: string;
			sessionLength: number;
			slots: {
				id: number;
				title: string;
				surveyId: string;
				nicheId: null | string;
				start: string;
				end: string;
				meetLink: string;
				surveyLink: null | string;
				email: string;
				name: string;
				allDay: boolean;
				bookedAt: string;
			}[];
		};
		questions: Array<{
			id: string;
			surveyId: string;
			questionType: string;
			content: {
				text: string;
				description: string;
			};
			settings: {
				skip: boolean;
			};
			orderPosition: 1;
			display: {
				showIf: any[];
				skipIf: any[];
			};
		}>;
	}>;
	testingTargetUrls: string[];
	testingGoal: string;
	isModerated: boolean;
};

export type InternalDashboardQuestionnaire = {
	id: string;
	name: string;
	allowMultipleResponse: boolean;
	description: string;
	pixelId: string | null;
	workspace: {
		id: string;
		name: string;
	};
	macroTargetingGroup: Macro;
	brand: {
		id: string;
		name: string;
	};
	questionnaireNiches: Niche[];
	creator: {
		id: string;
		firstName: string;
		lastName: string;
		email: string;
		locusId: string;
		companyName: string;
		phoneNumber: string | null;
		profilePicture: string | null;
	};
	createdAt: string | null;
	startTime: string | null;
	endTime: string | null;
	publishTime: null | string;
	cancelTime: null | string;
	status: SurveyStatus;
	paymentStatus: null | string;
	surveys: Array<{
		id: string;
		orderPosition: number;
		moderatedTesting?: {
			name: string;
			sessionLength: number;
			slots: {
				id: number;
				title: string;
				surveyId: string;
				nicheId: null | string;
				start: string;
				end: string;
				meetLink: string;
				surveyLink: null | string;
				email: string;
				name: string;
				allDay: boolean;
				bookedAt: string;
			}[];
		};
		questionnaireId: string;
		credits: number;
		instruction: Array<{
			id: string;
			instruction: string[];
		}>;
		totalRespondents: number;
		research: {
			id: string;
			researchModule: string;
			researchType: string;
			subResearch: string[];
		};
		questions: Array<{
			id: string;
			surveyId: string;
			questionType: string;
			content: {
				text: string;
				description: string;
			};
			settings: {
				skip: boolean;
			};
			orderPosition: 1;
			display: {
				showIf: any[];
				skipIf: any[];
			};
		}>;
	}>;
	testingTargetUrls: string[];
	testingGoal: string;
	isModerated: boolean;
};

export const READ_QUESTIONNAIRES = 'READ_QUESTIONNAIRES';
export type ReadQuestionnaires = ReduxAction<typeof READ_QUESTIONNAIRES, string>; //brandId

export const READ_QUESTIONNAIRES_FULFILLED = 'READ_QUESTIONNAIRES_FULFILLED';
export type ReadQuestionnairesFulfilled = ReduxAction<
	typeof READ_QUESTIONNAIRES_FULFILLED,
	InternalDashboardQuestionnaire
>;
export const PATCH_QUESTIONNAIRES = 'PATCH_QUESTIONNAIRES';
export type PatchQuestionnaires = ReduxAction<typeof PATCH_QUESTIONNAIRES, {questionnaireId:string,allowMultipleResponse:boolean}>; //brandId

export const PATCH_QUESTIONNAIRES_FULFILLED = 'PATCH_QUESTIONNAIRES_FULFILLED';
export type PatchQuestionnairesFulfilled = ReduxAction<
	typeof PATCH_QUESTIONNAIRES_FULFILLED,
	{questionnaireId:string,allowMultipleResponse:boolean}
>;

export const RESET_QUESTIONNAIRE = 'RESET_QUESTIONNAIRE';
export type ResetQuestionnaire = ReduxAction<typeof RESET_QUESTIONNAIRE, null>;

export const CANCEL_QUESTIONNAIRE = 'CANCEL_QUESTIONNAIRE';
export type CancelQuestionnaire = ReduxAction<typeof CANCEL_QUESTIONNAIRE, string>; // questionnaireId

export const CANCEL_QUESTIONNAIRE_FULFILLED = 'CANCEL_QUESTIONNAIRE_FULFILLED';
export type CancelQuestionnaireFulfilled = ReduxAction<typeof CANCEL_QUESTIONNAIRE_FULFILLED, string>; // questionnaireId

export const SCHEDULE_QUESTIONNAIRE = 'SCHEDULE_QUESTIONNAIRE';
export type ScheduleQuestionnaire = ReduxAction<typeof SCHEDULE_QUESTIONNAIRE, string>; //questionnaireId

export const SCHEDULE_QUESTIONNAIRE_FULFILLED = 'SCHEDULE_QUESTIONNAIRE_FULFILLED';
export type ScheduleQuestionnaireFulfilled = ReduxAction<typeof SCHEDULE_QUESTIONNAIRE_FULFILLED, string>; //questionnaireId

export const ACTIVATE_QUESTIONNAIRE = 'ACTIVATE_QUESTIONNAIRE';
export type ActivateQuestionnaire = ReduxAction<typeof ACTIVATE_QUESTIONNAIRE, string>; //questionnaireId

export const UPDATE_PIXELID = 'UPDATE_PIXELID';
export type UpdatePixelId = ReduxAction<typeof UPDATE_PIXELID, { questionnaireId: string; pixelId: string | null }>;

export type QuestionnairesAction =
	| ReadQuestionnaires
	| ReadQuestionnairesFulfilled
	| PatchQuestionnaires
	| PatchQuestionnairesFulfilled
	| CancelQuestionnaire
	| CancelQuestionnaireFulfilled
	| ScheduleQuestionnaire
	| ScheduleQuestionnaireFulfilled
	| ActivateQuestionnaire
	| ResetQuestionnaire
	| UpdatePixelId;

export type QuestionnairesReducer = ReduxReducer<InternalDashboardState, QuestionnairesAction>;
