import React, { ReactNode, useContext, useState } from 'react';
import ConfirmDrawer, { ConfirmDrawerProps } from './ConfirmDrawer';
type drawerContextType = {
	drawers: ConfirmDrawerProps[];
	push: (drawer: ConfirmDrawerProps) => void;
	pop: () => void;
};
const DrawerProvider = ({ children }: { children: ReactNode; }) => {
	const [drawers, setDrawers] = useState<ConfirmDrawerProps[]>([]);
	const drawerUtilities: drawerContextType = {
		drawers,
		push: (drawer) => setDrawers([...drawers, drawer]),
		pop: () => setDrawers(drawers.slice(1)),
	};
	return (
		<>
			{<ConfirmDrawer onClose={() => drawerUtilities.pop()} visible={!!drawers.length} {...drawers[0]} />}
			<DrawerContext.Provider value={drawerUtilities}>{children}</DrawerContext.Provider>
		</>
	);
};

export const DrawerContext = React.createContext<drawerContextType>({
	drawers: [],
	push: () => { },
	pop: () => { },
});
export const useDrawer = () => useContext(DrawerContext);

export default DrawerProvider;
