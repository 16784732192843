import axios from '@utils/axios';
import { call, takeLatest } from 'redux-saga/effects';
import { environment } from '../../../environment';
import { getApiEndPointUrl } from '../../../shared/utils/getApiEndpointUrl';
import { getApiError } from '../../../shared/utils/getApiError';
import { putEffect } from '../../../shared/utils/putEffect';
import { pend, fulfill, reject } from '../../promise/actions';
import {
	cancelQuestionnaireFulfilledAction,
	patchQuestionnaires,
	patchQuestionnairesFulfilledAction,
	readQuestionnaires,
	readQuestionnairesFulfilledAction,
	scheduleQuestionnaireFulfilledAction,
} from './actions';
import { cancelRoute, questionnairesEndPoint, scheduleRoute } from './const';
import {
	CancelQuestionnaire,
	CANCEL_QUESTIONNAIRE,
	PatchQuestionnaires,
	PATCH_QUESTIONNAIRES,
	ReadQuestionnaires,
	READ_QUESTIONNAIRES,
	ScheduleQuestionnaire,
	SCHEDULE_QUESTIONNAIRE,
	UPDATE_PIXELID,
	UpdatePixelId,
} from './type';
import { prepareQuestionnaire } from './utils';

const readQuestionnairesAPI = (url: string) => axios.get(url);

function* getQuestionnairesSaga(action: ReadQuestionnaires) {
	try {
		yield putEffect(pend(readQuestionnaires));
		const res = yield call(
			readQuestionnairesAPI,
			`${environment.apiUrl}${questionnairesEndPoint}${action.payload}/info`
		);
		yield putEffect(readQuestionnairesFulfilledAction(prepareQuestionnaire(res.data)));
		yield putEffect(fulfill(readQuestionnaires));
	} catch (e) {
		yield putEffect(reject(readQuestionnaires, getApiError(e)));
	}
}
function* putQuestionnairesSaga(action: PatchQuestionnaires) {
	const {questionnaireId,allowMultipleResponse} = action.payload
	try {
		yield putEffect(pend(patchQuestionnaires));
		 yield axios.patch(getApiEndPointUrl(questionnairesEndPoint, questionnaireId), {
			allowMultipleResponse
		});
		 yield putEffect(patchQuestionnairesFulfilledAction(action.payload));
		yield putEffect(fulfill(patchQuestionnaires));
	} catch (e) {
		yield putEffect(reject(patchQuestionnaires, getApiError(e)));
	}
}
const cancelQuestionnaireRequest = (questionnaireId: string) =>
	axios.post(getApiEndPointUrl(questionnairesEndPoint, questionnaireId, cancelRoute));

function* cancelQuestionnaire(action: CancelQuestionnaire) {
	try {
		yield putEffect(pend(cancelQuestionnaire));
		yield call(cancelQuestionnaireRequest, action.payload);
		yield putEffect(cancelQuestionnaireFulfilledAction(action.payload));
		yield putEffect(fulfill(cancelQuestionnaire));
	} catch (e) {
		yield putEffect(reject(cancelQuestionnaire, getApiError(e)));
	}
}
const scheduleQuestionnaireRequest = (questionnaireId: string) =>
	axios.post(getApiEndPointUrl(questionnairesEndPoint, questionnaireId, scheduleRoute));

function* scheduleQuestionnaire(action: ScheduleQuestionnaire) {
	try {
		yield putEffect(pend(scheduleQuestionnaire));
		yield call(scheduleQuestionnaireRequest, action.payload);
		yield putEffect(scheduleQuestionnaireFulfilledAction(action.payload));
		yield putEffect(fulfill(scheduleQuestionnaire));
	} catch (e) {
		yield putEffect(reject(scheduleQuestionnaire, getApiError(e)));
	}
}

function* updatePixelIdSaga(action: UpdatePixelId) {
	try {
		yield putEffect(pend(scheduleQuestionnaire));
		const { questionnaireId, pixelId } = action.payload;
		yield axios.patch(getApiEndPointUrl(questionnairesEndPoint, questionnaireId), {
			pixelId,
		});
		yield putEffect(fulfill(updatePixelIdSaga));
	} catch (e) {
		yield putEffect(reject(updatePixelIdSaga, getApiError(e)));
	}
}

export const questionnairesSagas = [
	function* readQuestionnairesWatcherSaga() {
		yield takeLatest(READ_QUESTIONNAIRES, getQuestionnairesSaga);
	},
	function* cancelQuestionnaireWatcher() {
		yield takeLatest(CANCEL_QUESTIONNAIRE, cancelQuestionnaire);
	},
	function* scheduleQuestionnaireWatcher() {
		yield takeLatest(SCHEDULE_QUESTIONNAIRE, scheduleQuestionnaire);
	},
	function* putQuestionnaireWatcher() {
		yield takeLatest(PATCH_QUESTIONNAIRES, putQuestionnairesSaga);
	},

	function* putQuestionnaireWatcher() {
		yield takeLatest(UPDATE_PIXELID, updatePixelIdSaga);
	},
];
