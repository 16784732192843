import { InternalDashboardItem } from '../surveys/types';
import {
	QuestionnairesReducer,
	READ_QUESTIONNAIRES_FULFILLED,
	SCHEDULE_QUESTIONNAIRE_FULFILLED,
	CANCEL_QUESTIONNAIRE_FULFILLED,
	RESET_QUESTIONNAIRE,
	PATCH_QUESTIONNAIRES_FULFILLED,
	UPDATE_PIXELID,
} from './type';
import { assign } from 'shared/utils/assign';
const filterById = (idToRemove: string) => (survey: InternalDashboardItem) => survey.id !== idToRemove;

export const questionnairesReducer: QuestionnairesReducer = (state, action) => {
	switch (action.type) {
		case READ_QUESTIONNAIRES_FULFILLED:
			return {
				...state,
				questionnaire: action.payload,
			};
		case SCHEDULE_QUESTIONNAIRE_FULFILLED:
			return {
				...state,
				items: state.items.filter(filterById(action.payload)),
			};
		case PATCH_QUESTIONNAIRES_FULFILLED:
			const {allowMultipleResponse }= action.payload
			return  assign(
				{
					questionnaire:	{allowMultipleResponse},					
				},
				state
			); 
		case CANCEL_QUESTIONNAIRE_FULFILLED:
			return {
				...state,
				items: state.items.filter(filterById(action.payload)),
			};
		case RESET_QUESTIONNAIRE:
			return {
				...state,
				questionnaire: null,
			};
		case UPDATE_PIXELID:
			const newState = {
				...state,
				questionnaire: {
					...state.questionnaire,
					pixelId: action.payload.pixelId,
				},
			};
			return {...newState};
		default:
			return state;
	}
};
