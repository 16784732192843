import axios from '@utils/axios';
import { takeLatest } from 'redux-saga/effects';
import { getResponseApiEndPointUrl } from '../../../shared/utils/getApiEndpointUrl';
import { getApiError } from '../../../shared/utils/getApiError';
import { putEffect } from '../../../shared/utils/putEffect';
import { fulfill, pend, reject } from '../../promise/actions';
import {
	deleteResponsesVisibilty,
	deleteResponsesVisibiltyFulfilled,
	putResponsesVisibilty,
	putResponsesVisibiltyFulfilled,
	readResponsesVisibilty,
	readResponsesVisibiltyFulfilled,
} from './actions';
import { nicheEndPoint, surveyEndPoint, visibilityEndPoint } from './const';
import {
	DeleteResponsesVisibilty,
	DELETE_RESPONSES_VISIBILITY,
	PutResponsesVisibilty,
	PUT_RESPONSES_VISIBILITY,
	ReadResponsesVisibilty,
	READ_RESPONSES_VISIBILITY,
} from './types';

function readResponsesVisibiltyAPI(action) {
	const { questionnaireId, nicheId } = action;
	return axios.get(
		getResponseApiEndPointUrl(surveyEndPoint, questionnaireId, nicheEndPoint, visibilityEndPoint),
		{ params: { cohorts: nicheId } }
	);
}

function putResponsesVisibiltyAPI(action) {
	const { questionnaireId, nicheId } = action;
	return axios.put(
		getResponseApiEndPointUrl(surveyEndPoint, questionnaireId, nicheEndPoint, visibilityEndPoint),
		{},
		{ params: { cohorts: nicheId } }
	);
}

function deleteResponsesVisibiltyAPI(action) {
	const { questionnaireId, nicheId } = action;
	return axios.delete(
		getResponseApiEndPointUrl(surveyEndPoint, questionnaireId, nicheEndPoint, visibilityEndPoint),
		{ params: { cohorts: nicheId } }
	);
}

function* readResponsesVisibiltySaga(action: ReadResponsesVisibilty) {
	try {
		yield putEffect(pend(readResponsesVisibilty));
		const res = yield readResponsesVisibiltyAPI(action.payload);
		yield putEffect(readResponsesVisibiltyFulfilled(res.data));
		yield putEffect(fulfill(readResponsesVisibilty));
	} catch (e) {
		yield putEffect(reject(readResponsesVisibilty, getApiError(e)));
	}
}
function* putResponsesVisibiltySaga(action: PutResponsesVisibilty) {
	try {
		yield putEffect(pend(putResponsesVisibilty));
		yield putResponsesVisibiltyAPI(action.payload);
		yield putEffect(putResponsesVisibiltyFulfilled(action.payload));
		yield putEffect(fulfill(putResponsesVisibilty));
	} catch (e) {
		yield putEffect(reject(putResponsesVisibilty, getApiError(e)));
	}
}
function* deleteResponsesVisibiltySaga(action: DeleteResponsesVisibilty) {
	try {
		yield putEffect(pend(deleteResponsesVisibilty));
		yield deleteResponsesVisibiltyAPI(action.payload);
		yield putEffect(deleteResponsesVisibiltyFulfilled(action.payload));
		yield putEffect(fulfill(deleteResponsesVisibilty));
	} catch (e) {
		yield putEffect(reject(deleteResponsesVisibilty, getApiError(e)));
	}
}
export const getResponsesVisibiltyWatchers = [
	function* () {
		yield takeLatest(READ_RESPONSES_VISIBILITY, readResponsesVisibiltySaga);
	},
	function* () {
		yield takeLatest(PUT_RESPONSES_VISIBILITY, putResponsesVisibiltySaga);
	},
	function* () {
		yield takeLatest(DELETE_RESPONSES_VISIBILITY, deleteResponsesVisibiltySaga);
	},
];
