import { ReduxAction } from '../../../shared/types/ReduxAction';
import { ReduxReducer } from '../../../shared/types/ReduxReducer';
import { SurveyResponses } from '../type';
export const READ_RESPONSES_VISIBILITY = 'READ_RESPONSES_VISIBILITY';
export const READ_RESPONSES_VISIBILITY_FULFILLED = 'READ_RESPONSES_VISIBILITY_FULFILLED';
export const PUT_RESPONSES_VISIBILITY = 'PUT_RESPONSES_VISIBILITY';
export const PUT_RESPONSES_VISIBILITY_FULFILLED = 'PUT_RESPONSES_VISIBILITY_FULFILLED';
export const DELETE_RESPONSES_VISIBILITY = 'DELETE_RESPONSES_VISIBILITY';
export const DELETE_RESPONSES_VISIBILITY_FULFILLED = 'DELETE_RESPONSES_VISIBILITY_FULFILLED';

export type ReadResponsesVisibilty = ReduxAction<
	typeof READ_RESPONSES_VISIBILITY,
	{ questionnaireId: string; nicheId: Array<string> }
>;
export type ReadResponsesVisibiltyFulfilled = ReduxAction<typeof READ_RESPONSES_VISIBILITY_FULFILLED, boolean>;
export type PutResponsesVisibilty = ReduxAction<
	typeof PUT_RESPONSES_VISIBILITY,
	{ questionnaireId: string; nicheId: Array<string> }
>;
export type PutResponsesVisibiltyFulfilled = ReduxAction<
	typeof PUT_RESPONSES_VISIBILITY_FULFILLED,
	{ questionnaireId: string; nicheId: Array<string> }
>;
export type DeleteResponsesVisibilty = ReduxAction<
	typeof DELETE_RESPONSES_VISIBILITY,
	{ questionnaireId: string; nicheId: Array<string> }
>;
export type DeleteResponsesVisibiltyFulfilled = ReduxAction<
	typeof DELETE_RESPONSES_VISIBILITY_FULFILLED,
	{ questionnaireId: string; nicheId: Array<string> }
>;

export type ResponsesVisibiltyAction =
	| ReadResponsesVisibilty
	| ReadResponsesVisibiltyFulfilled
	| PutResponsesVisibilty
	| PutResponsesVisibiltyFulfilled
	| DeleteResponsesVisibilty
	| DeleteResponsesVisibiltyFulfilled;

export type ResponsesVisibiltyReducer = ReduxReducer<SurveyResponses, ResponsesVisibiltyAction>;
