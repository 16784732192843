export const storeInLocalStorage = <T>(key: string, item: T): void => {
	localStorage.setItem(key, JSON.stringify(item));
};

export const getValueForKeyInLocalStorage = <T>(key: string): T | null => {
	const item = localStorage.getItem(key);
	try {
		return !!item ? (JSON.parse(item) as T) : null;
	} catch (e) {
		return null;
	}
};

export const removeFromLocalStorage = (key: string): void => {
	localStorage.removeItem(key);
};

export const clearLocalStorage = (): void => {
	localStorage.clear();
};

export const storeInSessionStorage = <T>(key: string, item: T): void => {
	sessionStorage.setItem(key, JSON.stringify(item));
};

export const getValueForKeySessionStorage = <T>(key: string): T | null => {
	const item = sessionStorage.getItem(key);
	try {
		return !!item ? (JSON.parse(item) as T) : null;
	} catch (e) {
		return null;
	}
};

export const removeFromSessionStorage = (key: string): void => {
	sessionStorage.removeItem(key);
};

export const clearSessionStorage = (): void => {
	sessionStorage.clear();
};

export const shouldRememberUser = <T>(condition: boolean, key: string, item: T) => {
	if (condition) {
		storeInLocalStorage(key, item);
	} else {
		storeInSessionStorage(key, item);
	}
};
