import {
	PromiseState,
	FULFILLED,
	PENDING,
	REJECTED,
	RESET,
	RESET_ALL,
	PromiseAction,
	PromiseReducer,
	SuperPendingArray,
	SuperArray,
} from './types';

const initialState: PromiseState = {
	fulfilled: new SuperArray(),
	rejected: new SuperArray(),
	pending: new SuperPendingArray(),
};

export const promiseReducer: PromiseReducer = (state = initialState, action: PromiseAction) => {
	switch (action.type) {
		case REJECTED:
			return {
				...state,
				rejected: new SuperArray(...state.rejected, action.payload),
				pending: state.pending.filter((req) => req !== action.payload.id),
			};
		case FULFILLED:
			return {
				...state,
				fulfilled: state.fulfilled.concat(action.payload),
				pending: state.pending.filter((req) => req !== action.payload.id),
			};
		case PENDING:
			return {
				...state,
				fulfilled: state.fulfilled.filter((fulfilled) => action.payload !== fulfilled.id),
				rejected: state.rejected.filter((rejected) => !action.payload !== rejected.id),
				pending: state.pending.concat(action.payload),
			};
		case RESET:
			return {
				...state,
				fulfilled: state.fulfilled.filter((fulfilled) => !action.payload.includes(fulfilled.id)),
				rejected: state.rejected.filter((rejected) => !action.payload.includes(rejected.id)),
			};
		case RESET_ALL:
			return initialState;
		default:
			return state;
	}
};
