import axios from '@utils/axios';
import { notification } from 'antd';
import { takeLatest, takeLeading } from 'redux-saga/effects';
import { getResponseApiEndPointUrl } from '../../../shared/utils/getApiEndpointUrl';
import { getApiError } from '../../../shared/utils/getApiError';
import { putEffect } from '../../../shared/utils/putEffect';
import { pend, fulfill, reject } from '../../promise/actions';
import {
	patchResponses,
	patchResponsesFulfilled,
	patchResponse,
	patchResponseFulfilled,
	readResponses,
	readResponsesFulfilled,
	updateVideoMarkers,
} from './actions';
import {
	BulkApprovalEndPoint,
	nicheEndPoint,
	ResponsesEndpoint,
	surveyEndPoint,
	SurveyResponsesEndPoint,
	videoRecordingsEndPoint,
	BulkApprovalUpdateEndPoint,
} from './const';
import {
	PatchResponse,
	PatchResponses,
	PATCH_RESPONSE,
	PATCH_RESPONSES,
	ReadResponses,
	READ_RESPONSES,
	UpdateVideoMarkers,
	UpdateVideoMarkersPayload,
	UPDATE_VIDEO_MARKERS,
} from './types';
function readResponsesAPI(action) {
	const { id, ...params } = action;
	return axios.get(getResponseApiEndPointUrl(surveyEndPoint, id, SurveyResponsesEndPoint), {
		params,
	});
}
function* readResponsesSaga(action: ReadResponses) {
	try {
		yield putEffect(pend(readResponses));
		const res = yield readResponsesAPI(action.payload);
		yield putEffect(readResponsesFulfilled(res.data));
		yield putEffect(fulfill(readResponses));
	} catch (e) {
		yield putEffect(reject(readResponses, getApiError(e)));
	}
}
function patchResponseAPI(action) {
	const { questionnaireId, approved, respndentIdList, isApproveAll = false, data } = action;
	if (isApproveAll) {
		return axios.patch(getResponseApiEndPointUrl(surveyEndPoint, questionnaireId, BulkApprovalUpdateEndPoint), data);
	}
	return axios.patch(getResponseApiEndPointUrl(surveyEndPoint, questionnaireId, BulkApprovalUpdateEndPoint), {
		approved,
		respndentIdList: respndentIdList,
	});
}
function* patchResponseSaga(action: PatchResponse) {
	try {
		yield putEffect(pend(patchResponse));
		yield patchResponseAPI(action.payload);
		const res = yield readResponsesAPI(action.payload.readResonsePayload);
		yield putEffect(readResponsesFulfilled(res.data));
		yield putEffect(patchResponseFulfilled(action.payload));
		yield putEffect(fulfill(patchResponse));
		notification.success({
			message: 'Success',
			description: action.payload.successMessage,
		});
	} catch (e) {
		yield putEffect(reject(patchResponse, getApiError(e)));
	}
}
function updateVideoMarkersAPI({ videoMarkers, responseId, questionnaireId }: UpdateVideoMarkersPayload) {
	return axios.put(getResponseApiEndPointUrl(surveyEndPoint, questionnaireId, videoRecordingsEndPoint, responseId), {
		videoMarkers,
	});
}
function* updateVideoMarkersSaga(action: UpdateVideoMarkers) {
	try {
		yield putEffect(pend(updateVideoMarkers));
		yield updateVideoMarkersAPI(action.payload);
		yield putEffect(fulfill(updateVideoMarkers));
	} catch (e) {
		yield putEffect(reject(updateVideoMarkers, getApiError(e)));
	}
}
function patchResponsesAPI(action) {
	const { questionnaireId, nicheId } = action;
	return axios.patch(
		getResponseApiEndPointUrl(
			surveyEndPoint,
			questionnaireId,
			nicheEndPoint,
			nicheId,
			ResponsesEndpoint,
			BulkApprovalEndPoint
		)
	);
}
function* patchResponsesSaga(action: PatchResponses) {
	try {
		yield putEffect(pend(patchResponses));
		yield patchResponsesAPI(action.payload);
		yield putEffect(patchResponsesFulfilled());
		yield putEffect(fulfill(patchResponses));
	} catch (e) {
		yield putEffect(reject(patchResponses, getApiError(e)));
	}
}

export const getSurveyResponsesWatchers = [
	function* () {
		yield takeLatest(READ_RESPONSES, readResponsesSaga);
	},
	function* () {
		yield takeLeading(PATCH_RESPONSE, patchResponseSaga);
	},
	function* () {
		yield takeLeading(PATCH_RESPONSES, patchResponsesSaga);
	},
	function* () {
		yield takeLeading(UPDATE_VIDEO_MARKERS, updateVideoMarkersSaga);
	},
];
