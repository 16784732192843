import {
	ReadCustomVariables,
	READ_CUSTOM_VARIABLES,
	ReadCustomVariablesFulfilled,
	READ_CUSTOM_VARIABLES_FULFILLED,
	Variable,
	UPDATE_CUSTOM_VARIABLES,
	UPDATE_CUSTOM_VARIABLES_FULFILLED,
	UpdateCustomVariablePayload,
	UpdateCustomVariables,
	UpdateCustomVariableFulfilled,
} from './types';
export const readCustomVariables = (payload: string): ReadCustomVariables => ({
	type: READ_CUSTOM_VARIABLES,
	payload,
});

export const readCustomVariablesFulfilled = (payload: Variable[]): ReadCustomVariablesFulfilled => ({
	type: READ_CUSTOM_VARIABLES_FULFILLED,
	payload: payload,
});

export const updateCustomVariable = (payload: UpdateCustomVariablePayload): UpdateCustomVariables => ({
	type: UPDATE_CUSTOM_VARIABLES,
	payload,
});

export const updateCustomVariableFulfilled = (payload: UpdateCustomVariablePayload): UpdateCustomVariableFulfilled => ({
	type: UPDATE_CUSTOM_VARIABLES_FULFILLED,
	payload: payload,
});
