import { Modal } from 'pyxis-ui-kit';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
	& .ant-modal-content {
		text-align: center;
		background: none;
		box-shadow: none;
	}
`;
