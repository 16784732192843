import { ReduxAction } from '../../shared/types/ReduxAction';
import { ReduxReducer } from '../../shared/types/ReduxReducer';
export const READ_QUOTA = 'READ_QUOTA';
export const READ_QUOTA_FULFILLED = 'READ_QUOTA_FULFILLED';
export const CREATE_QUOTA = 'CREATE_QUOTA';
export const CREATE_QUOTA_FULFILLED = 'CREATE_QUOTA_FULFILLED';
export const UPDATE_QUOTA = 'UPDATE_QUOTA';
export const UPDATE_QUOTA_FULFILLED = 'UPDATE_QUOTA_FULFILLED';
export const DELETE_QUOTA = 'DELETE_QUOTA';
export const DELETE_QUOTA_FULFILLED = 'DELETE_QUOTA_FULFILLED';
export const CLONE_QUOTA = 'CLONE_QUOTA';
export const CLONE_QUOTA_FULFILLED = 'CLONE_QUOTA_FULFILLED';

export type QuotaInfo = {
    id:string,
    label:any,
    limit:number,
	stopUpdate:boolean,
    quotaConditionList:
	  {
	   QuotaOn: string,
	   recordId: string,
	   apiIdentifier:string,
	   logic: string,
	   questionType:string,
	   response: any,
	  }[][],
    questionnaireId:string,
	endWith:string,
		url:string,
}
export type QuotaInfoState = {
		entities:QuotaInfo[],
		metaData:{
			total:number
		}
};
 export type readQuotaInfo={	id: string;
	offset?: number;
	limit?: number;
}

export type UpdateQuotaInfoStatePayload = {
	redirectURLs: QuotaInfoState[];
	surveyId: string;
};
export type clonePayload = {
	existingQuotaId:string;
	newQuotaId:string
};
export type ReadQuota = ReduxAction<typeof READ_QUOTA,readQuotaInfo >;
export type ReadQuotaFulfilled = ReduxAction<typeof READ_QUOTA_FULFILLED, QuotaInfoState[]>;
export type CreateQuota = ReduxAction<typeof CREATE_QUOTA, string>;
export type CreateQuotaFulfilled = ReduxAction<typeof CREATE_QUOTA_FULFILLED, QuotaInfoState[]>;
export type UpdateQuota = ReduxAction<typeof UPDATE_QUOTA, QuotaInfo>;
export type UpdateQuotaFulfilled = ReduxAction<typeof UPDATE_QUOTA_FULFILLED, QuotaInfo>;
export type DeleteQuota = ReduxAction<typeof DELETE_QUOTA, string>;
export type DeleteQuotaFulfilled = ReduxAction<typeof DELETE_QUOTA_FULFILLED, string>;
export type CloneQuota = ReduxAction<typeof CLONE_QUOTA, clonePayload>;
export type CloneQuotaFulfilled = ReduxAction<typeof CLONE_QUOTA_FULFILLED, any>;

export type QuotaAction =
	| ReadQuota
	| ReadQuotaFulfilled
	| CreateQuota
	| CreateQuotaFulfilled
	| UpdateQuota
	| UpdateQuotaFulfilled
	| DeleteQuota
	| DeleteQuotaFulfilled
	| CloneQuota
	| CloneQuotaFulfilled;

export type QuotaReducer = ReduxReducer<QuotaInfoState, QuotaAction>;
