import {
	READ_QUOTA_FULFILLED,
	CREATE_QUOTA_FULFILLED,
	UPDATE_QUOTA_FULFILLED,
	DELETE_QUOTA_FULFILLED,
	QuotaAction,
	CLONE_QUOTA_FULFILLED,
} from './types';
const initialState = {
	entities: [],
		metaData: { total: 0 },
	
};
export const quotasReducer: any = (state = initialState, action: QuotaAction) => {
	switch (action.type) {
		case READ_QUOTA_FULFILLED:
			return {
				...state,
				...action.payload
			};
		case CREATE_QUOTA_FULFILLED:
			return {
				...state,
				entities: [...state.entities, action.payload],
			};
		case UPDATE_QUOTA_FULFILLED:
			return {
				...state,
					entities: state.entities?.map((list) => (list['id'] === action?.payload['id'] ? {...action.payload,stopUpdate:true} : list)),
			};
		case DELETE_QUOTA_FULFILLED:
			return {
				...state,
					entities: state.entities?.filter((list) => list['id'] !== action?.payload),
			};
		case CLONE_QUOTA_FULFILLED:
			return {
				...state,
					entities: state?.entities?.concat(action.payload),
			};
		default:
			return state;
	}
};
