import {
	 ReadQuota,
	 ReadQuotaFulfilled,
	 CreateQuota,
	 CreateQuotaFulfilled,
	 UpdateQuota,
	 UpdateQuotaFulfilled,
	 DeleteQuota,
	 DeleteQuotaFulfilled,
     READ_QUOTA,
  READ_QUOTA_FULFILLED,
  CREATE_QUOTA ,
  CREATE_QUOTA_FULFILLED,
  UPDATE_QUOTA ,
  UPDATE_QUOTA_FULFILLED ,
  DELETE_QUOTA ,
  DELETE_QUOTA_FULFILLED, 
  QuotaInfo,
  
  readQuotaInfo,
  CLONE_QUOTA,
  CLONE_QUOTA_FULFILLED,
  CloneQuotaFulfilled,
  CloneQuota,
  clonePayload,
} from './types';
export const readQuota = (payload: readQuotaInfo): ReadQuota => ({
	type: READ_QUOTA,
	payload,
});

export const readQuotaFulfilled = (payload: any): ReadQuotaFulfilled => ({
	type: READ_QUOTA_FULFILLED,
	payload: payload,
});
export const createQuota = (payload: string): CreateQuota => ({
	type: CREATE_QUOTA,
	payload,
});

export const createQuotaFulfilled = (payload: any): CreateQuotaFulfilled => ({
	type: CREATE_QUOTA_FULFILLED,
	payload: payload,
});

export const updateQuota = (payload: QuotaInfo): UpdateQuota => ({
	type: UPDATE_QUOTA,
	payload,
});

export const updateQuotaFulfilled = (payload: any): UpdateQuotaFulfilled => ({
	type: UPDATE_QUOTA_FULFILLED,
	payload: payload,
});
export const deleteQuota = (payload: string): DeleteQuota => ({
	type: DELETE_QUOTA,
	payload,
});

export const deleteQuotaFulfilled = (payload: string): DeleteQuotaFulfilled => ({
	type: DELETE_QUOTA_FULFILLED,
	payload,
});
export const cloneQuota = (payload: clonePayload): CloneQuota => ({
	type: CLONE_QUOTA,
	payload,
});

export const cloneQuotaFulfilled = (payload: any): CloneQuotaFulfilled => ({
	type: CLONE_QUOTA_FULFILLED,
	payload,
});
