import {
	GetWorkspacesAction,
	GET_WORKSPACES,
	SET_WORKSPACES,
	SetWorkspaceAction,
	SetWorkspaceActionPayload,
	Workspace,
	UpdateWorkspaceAction,
	UPDATE_WORKSPACE,
	UpdateWorkspaceFulfilled,
	UPDATE_WORKSPACE_FULFILLED,
	ReadWorkspacesPayload,
} from './types';

export const getWorkspaceAction = (payload: ReadWorkspacesPayload): GetWorkspacesAction => ({
	type: GET_WORKSPACES,
	payload,
});

export const setWorkspacesAction: (workspaces: SetWorkspaceActionPayload) => SetWorkspaceAction = (payload) => ({
	type: SET_WORKSPACES,
	payload,
});

export const updateWorkspaceAction: (workspaceId: string, updates: Partial<Workspace>) => UpdateWorkspaceAction = (
	workspaceId,
	updates
) => ({
	type: UPDATE_WORKSPACE,
	payload: { workspaceId, updates },
});

export const updateWorkspaceFulfilled: (
	workspaceId: string,
	updates: Partial<Workspace>
) => UpdateWorkspaceFulfilled = (workspaceId, updates) => ({
	type: UPDATE_WORKSPACE_FULFILLED,
	payload: { workspaceId, updates },
});
